import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { KeyboardDatePicker } from '@material-ui/pickers'
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import CalendarIcon from '@material-ui/icons/DateRange'
import ClockIcon from '@material-ui/icons/AccessTime'
import { withAppContext } from '../../lib/context'
import {
  GENDERS, ISOLATION, COVID
} from '../../constants/shared'
import { setFilter as setFilterAction } from '../../actions/offers'

const styles = theme => {
  const unit = theme.spacing(1)

  return {
    container: {
      marginBottom: 2 * unit,
      width: '100%',
      '& > div:last-child': {
        paddingBottom: 2 * unit
      }
    },
    results: {
      textAlign: 'center',
      paddingBottom: 0
    },
    selectOption: {
      textTransform: 'capitalize',
      minWidth: '100%'
    },
    formControl: {
      width: '100%',
      margin: 'normal'
    }
  }
}

const emptyState = () => ({
  bedType: '',
  gender: '',
  covid: '',
  isolation: '',
  involvedHospitals: '',
  possibleHospitalsFiltered: '',
  date: null
})

class Filter extends React.Component {
  state = emptyState()

  componentDidMount = () => {
    const { defaultFilter } = this.props

    this.setState({
      ...defaultFilter
    })
  }

  handleChange = element => event => {
    const { sendChange, setFilter } = this.props

    const value1 = event.target.value

    const value = value1 === 'All' ? '' : value1

    this.setState({ [element]: value })

    const filter = { ...this.state, [element]: value }
    sendChange(filter)
    setFilter(filter)
  }

  handleCleanFilters = () => {
    const { sendChange, setFilter } = this.props
    this.setState(emptyState())
    const filter = emptyState()
    sendChange(filter)
    setFilter(filter)
  }

  handleSeeResults = () => {
    const { setTabIndex } = this.props

    setTabIndex(1)
  }

  render () {
    const {
      classes, t, possibleHospitals, hospitalType, possibleBedTypes
    } = this.props
    const {
      bedType, gender, covid, isolation, involvedHospitals
    } = this.state

    const date = moment(this.state.date || new Date())

    const possibleHospitalsFiltered = (bedType !== '')
      ? [({ _id: 'all', name: 'All', bedTypes: '' }), ...possibleHospitals.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(bedType) : null))]
      : possibleHospitals
    return (
      <form>
        <Grid container direction="column">
          <Grid item>
            <Card className={classes.container}>
              <CardContent>
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend">
                    {t('bed_type')}
                  </FormLabel>
                  <Select
                    className={classes.selectOption}
                    inputProps={{ id: 'bed-type' }}
                    value={bedType}
                    onChange={this.handleChange('bedType')}
                  >
                    {['All', ...possibleBedTypes].map(tu => (
                      <MenuItem value={tu} className={classes.selectOption} key={tu}>
                        {t(tu)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <CardContent>
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend">
                    {t('gender')}
                  </FormLabel>
                  <Select
                    className={classes.selectOption}
                    inputProps={{ id: 'gender' }}
                    name="gender"
                    value={gender}
                    onChange={this.handleChange('gender')}
                  >
                    {['All', ...GENDERS].map(tu => (
                      <MenuItem value={tu} className={classes.selectOption} key={tu}>
                        {t(tu)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <CardContent>
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend">
                    {t('covidStatus')}
                  </FormLabel>
                  <Select
                    className={classes.selectOption}
                    inputProps={{ id: 'covid' }}
                    name="covid"
                    value={covid}
                    onChange={this.handleChange('covid')}
                  >
                    {['All', ...COVID].map(tu => (
                      <MenuItem value={tu} className={classes.selectOption} key={tu}>
                        {t(tu)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <CardContent>
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend">
                    {t('isolation')}
                  </FormLabel>
                  <Select
                    className={classes.selectOption}
                    inputProps={{ id: 'isolation' }}
                    name="isolation"
                    value={isolation}
                    onChange={this.handleChange('isolation')}
                  >
                    {['All', ...ISOLATION].map(tu => (
                      <MenuItem value={tu} className={classes.selectOption} key={tu}>
                        {t(tu)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <CardContent>
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend" shrink="true">
                    {hospitalType === 'REQUESTER' ? t('HAI') : t('HAE')}
                  </FormLabel>
                  <Select
                    className={classes.selectOption}
                    inputProps={{ id: 'involved-hospitals' }}
                    value={involvedHospitals}
                    onChange={this.handleChange('involvedHospitals')}
                  >
                    {['All', ...possibleHospitalsFiltered].map(({ _id, name }) => (
                      <MenuItem value={_id === 'all' ? name : _id} className={classes.selectOption} key={_id || 'none'}>
                        {t(name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <CardContent>
                <FormControl>
                  <KeyboardDatePicker
                    className={classes.textField}
                    fullWidth
                    name="dateTimePicker"
                    allowKeyboardControl
                    ampm={false}
                    label={t('available_date')}
                    value={date}
                    onChange={v => this.handleChange('date')({ target: { value: v } })}
                    format="DD/MM/YYYY"
                    // onError={console.log}
                    leftArrowIcon={<LeftIcon />}
                    rightArrowIcon={<RightIcon />}
                    timeIcon={<ClockIcon />}
                    dateRangeIcon={<CalendarIcon />}
                  />
                </FormControl>

              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.container}>
              <CardContent className={classes.results}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={this.handleCleanFilters}
                >
                  {t('clean_filters')}
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Hidden mdUp>
            <Grid item>
              <Card className={classes.container}>
                <CardContent className={classes.results}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={this.handleSeeResults}
                  >
                    {t('see_results')}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Hidden>
        </Grid>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  hospitalType: state.hospitals[0].type,
  // eslint-disable-next-line no-nested-ternary
  possibleHospitals: state.hospitals.length === 0
    ? [] : (state.hospitals[0].type === 'OFFERER'
      ? ([({ _id: 'all', name: 'All', bedTypes: '' }), ...(state.hospitals[0].source)])
      : ([({ _id: 'all', name: 'All', bedTypes: '' }), ...(state.hospitals[0].target)])),
  possibleBedTypes: (state.hospitals[0].bedTypes)
  // possibleHospitals: state.hospitals.length === 0
  //   ? [] : [({ _id: '', name: 'All' }), ...(state.hospitals[0].source || []), ...(state.hospitals[0].target || [])]
})

const matpDispatchToProps = dispatch => ({
  setFilter: (filter) => dispatch(setFilterAction(filter))
})

export default withStyles(styles)(
  withTranslation()(
    connect(mapStateToProps, matpDispatchToProps)(
      withAppContext(Filter)
    )
  )
)
