import { takeLatest, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import axios from 'axios'
import Cookies from 'universal-cookie'
import firebase from 'firebase'
import { config as firebaseConfig } from '../firebaseSetup'
import { START_REFRESH_TOKEN_LIFECYCLE } from '../constants/user'

const cookies = new Cookies()

async function refreshAccessToken () {
  const { refreshToken } = cookies.get('login')
  const headers = { Authorization: firebaseConfig.amalfiLoginKey }
  const response = await axios.post(firebaseConfig.refreshUrl, { refreshToken }, { headers })
  return response.data && response.data.token
}
function * refreshTokenSaga () {
  while (true) {
    try {
      const token = yield call(refreshAccessToken)
      firebase.auth().signInWithCustomToken(token)
      const loginCookie = cookies.get('login')
      loginCookie.token = token
      cookies.set('login', loginCookie, { path: '/' })
    } catch (e) {
      console.error(e)
      cookies.remove('login')
      firebase.auth().signOut()
      window.location.href = '/'
    }
    yield delay(15 * 60 * 1000)
  }
}

export const refreshToken = takeLatest(START_REFRESH_TOKEN_LIFECYCLE, refreshTokenSaga)
