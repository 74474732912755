import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/ca'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import CalendarIcon from '@material-ui/icons/DateRange'
import ClockIcon from '@material-ui/icons/AccessTime'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import { GENDERS, ISOLATION, COVID } from '../../constants/shared'
import { OFFER_TYPES } from '../../constants/offers'

const styles = theme => {
  const unit = theme.spacing(1)

  return {
    textField: {
      marginBottom: 2 * unit
    },
    textArea: {
      marginTop: 2 * unit
    },
    formControl: {
      minWidth: '100%',
      marginBottom: theme.spacing(1)
    }
  }
}

// const ITEM_HEIGHT = 48
// const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null
}


class AddOfferModal extends React.Component {
  state = {
    numBeds: 1,
    bedType: '',
    gender: '',
    isolation: 'noIsolated',
    patientId: '',
    priority: false,
    vaccinated: false,
    passive: false,
    covid: 'noCovid',
    targetHosp: '',
    comments: '',
    possibleHospitalsFiltered: '',
    date: new Date(),
    creationDate: new Date()
  }

  componentDidMount = () => {
    const { filter, offerToEdit } = this.props
    let { bedType, gender, targetHosp } = filter
    const date = new Date()
    if (!targetHosp) {
      targetHosp = []
    }

    if (offerToEdit) {
      this.setState({ ...offerToEdit })
    } else {
      this.setState({
        bedType, gender, targetHosp, date
      })
    }

  }

  componentDidUpdate = (prevProps, prevState) => {
    const { filter } = this.props
    const { filter: prevFilter } = prevProps

    if (!_.isEqual(filter, prevFilter)) {
      const { bedType, gender } = filter
      this.setState({ bedType, gender, targetHosp: [] })
    }
  }

  handleChange = name => e => {
    this.setState({ [name]: e.target.value })
  }

  // TODO: check required fields
  // TODO: Warning: Each child in a list should have a unique "key" prop.
  createOffer = () => {
    const { createOfferHandler, offerToEdit } = this.props
    const {
      // eslint-disable-next-line no-unused-vars
      numBeds, bedType, gender, isolation, patientId, covid, targetHosp, comments, date, creationDate, possibleHospitalsFiltered, priority, vaccinated, passive
    } = this.state

    if (offerToEdit) {
      createOfferHandler({
        ...offerToEdit,
        numBeds,
        bedType,
        gender,
        isolation,
        patientId,
        priority,
        vaccinated,
        passive,
        covid,
        targetHosp,
        comments,
        possibleHospitalsFiltered,
        date: moment(creationDate) <= moment(date) ? moment(date).toJSON() : new Date().toJSON()
      })
    } else {
      createOfferHandler({
        _id: `${new Date().toJSON()}_${String(Math.random()).slice(2, 8)}`,
        numBeds,
        bedType,
        gender,
        isolation,
        patientId,
        priority,
        vaccinated,
        passive,
        covid,
        targetHosp,
        comments,
        possibleHospitalsFiltered,
        creationDate: new Date().toJSON(),
        date: moment(new Date()) <= moment(date) ? moment(date).toJSON() : new Date().toJSON()
      })
    }


    this.resetFields()
  }

  handleClose = () => {
    const { handleClose } = this.props

    this.resetFields()
    handleClose()
  }

  resetFields = () => {
    const { filter } = this.props
    const {
      bedType, gender
    } = filter

    this.setState({
      numBeds: 1,
      bedType,
      gender,
      isolation: 'noIsolated',
      patientId: '',
      priority: false,
      vaccinated: false,
      passive: false,
      covid: 'noCovid',
      comments: '',
      targetHosp: [],
      date: new Date(),
      creationDate: new Date()
    })
  }

  isValidForm = () => {
    const {
      numBeds, bedType, gender, isolation, covid, date
    } = this.state

    return (numBeds > 0) && (_.size(bedType) > 0) && (_.size(gender) > 0) && (_.size(isolation) > 0) && (_.size(covid) > 0) && moment(date).isValid()
  }

  componentWillUnmount = () => {
    if(this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render () {
    const {
      classes, title, open, offerType, t, possibleHospitals, possibleBedTypes, offerToEdit
    } = this.props
    const {
      // eslint-disable-next-line no-unused-vars
      numBeds, bedType, gender, isolation, patientId, priority, vaccinated, passive, covid, comments, date, creationDate
    } = this.state

    let targetHosp = _.isArray(this.state.targetHosp) ? this.state.targetHosp : [this.state.targetHosp]
    if (targetHosp.length === 1 && (targetHosp[0] === 'all' || targetHosp[0] === '') && !offerToEdit) {
      this.timeout = setTimeout(() => this.handleChange('targetHosp')({ target: { value: [] } }), 100)
    }

    const possibleHospitalsFiltered = (bedType !== '')
      ? [/*({ _id: 'all', name: 'All', bedTypes: '' }), */ ...possibleHospitals.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(bedType) : null))]
      : possibleHospitals
    // const possibleHospitalsFiltered = offerType === OFFER_TYPES.REQUEST && (bedType !== '')
    //   ? [({ _id: 'all', name: 'All', bedTypes: '' }), ...possibleHospitals.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(bedType) : null))]
    //   : possibleHospitals
    return (
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {offerType === OFFER_TYPES.OFFER && (
            <TextField
              className={classes.textField}
              fullWidth
              data-cy="numBedsTextField"
              required
              name="numBeds"
              label={t('Nombre de llits')}
              value={numBeds}
              type="number"
              onChange={this.handleChange('numBeds')}
            />
          )}
          {offerType === OFFER_TYPES.REQUEST && (
            <TextField
              className={classes.textField}
              fullWidth
              name="patientId"
              label={t('Id pacient')}
              value={patientId}
              onChange={this.handleChange('patientId')}
            />
          )}
          {offerType === OFFER_TYPES.REQUEST && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    name="priority"
                    checked={priority}
                    onChange={() => this.setState(state => ({ ...state, priority: !priority }))}
                  />
                }
                label={t('Priority')}
              />
              <FormControlLabel
              control={
                <Checkbox
                  name="vaccinated"
                  checked={vaccinated}
                  onChange={() => this.setState(state => ({ ...state, vaccinated: !vaccinated }))}
                />
              }
              label={t('Vaccinated')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="passive"
                  checked={passive}
                  onChange={() => this.setState(state => ({ ...state, passive: !passive }))}
                />
              }
              label={t('passive')}
            />
          </>
          )}
          <TextField
            className={classes.textField}
            fullWidth
            data-cy="bedTypeSelector"
            name="bedType"
            required
            select
            label={t('Tipus de llit')}
            value={bedType}
            onChange={e => {
              this.handleChange('bedType')(e)
              this.handleChange('targetHosp')({ target: { value: [] } })
            }}
          >
            {possibleBedTypes.map(tu => (
              <MenuItem value={tu} key={tu}>
                {t(tu)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            fullWidth
            data-cy="genderSelector"
            name="gender"
            required
            select
            label={t('Genere')}
            value={gender}
            onChange={this.handleChange('gender')}
          >
            {GENDERS.map(tu => (
              <MenuItem value={tu} key={tu}>
                {t(tu)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            fullWidth
            name="isolation"
            // defaultValue={t('noIsolated')}
            // required
            select
            label={t('isolation')}
            value={isolation}
            onChange={this.handleChange('isolation')}
          >
            {ISOLATION.map(tu => (
              <MenuItem value={tu} key={tu}>
                {t(tu)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            fullWidth
            name="covid"
            select
            label={t('covidStatus')}
            value={covid}
            onChange={this.handleChange('covid')}
          >
            {COVID.map(tu => (
              <MenuItem value={tu} key={tu}>
                {t(tu)}
              </MenuItem>
            ))}
          </TextField>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-hospital-multi">
              {t('Target Hospital')}
            </InputLabel>
            <Select
              labelId="select-hospital-multi"
              multiple
              value={targetHosp}
              placeholder={t('All')}
              onChange={(e) =>
                e.target.value.includes('all')
                  ? this.handleChange('targetHosp')({ target: { value: [] } })
                  : this.handleChange('targetHosp')(e)}
              input={<Input />}
              renderValue={
                (targetHosp) => targetHosp.map(id => {
                  const hh = possibleHospitalsFiltered.find(h => id === h._id)
                  return hh ? hh.name : ''
                })
                  .join(', ')
              }
              MenuProps={MenuProps}
            >
              <MenuItem key={"all"} value={"all"}>
                <Checkbox checked={(targetHosp).length === 0} />
                <ListItemText primary={t('All')} />
              </MenuItem>
              {possibleHospitalsFiltered.map(({ _id, name }) => (
                <MenuItem key={_id} value={_id}>
                  <Checkbox checked={(targetHosp).includes(_id)} />
                  <ListItemText primary={t(name)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <KeyboardDateTimePicker
            className={classes.textField}
            fullWidth
            name="dateTimePicker"
            allowKeyboardControl
            ampm={false}
            disablePast={!offerToEdit}
            label={offerType === OFFER_TYPES.OFFER ? t('available_at') : t('estimated_arrival')}
            value={moment(date)}
            onChange={value => { this.setState({ date: value }) }}
            format={t('date_format')}
            // onError={console.log}
            leftArrowIcon={<LeftIcon />}
            rightArrowIcon={<RightIcon />}
            timeIcon={<ClockIcon />}
            dateRangeIcon={<CalendarIcon />}
          />
          <TextField
            className={classes.textArea}
            fullWidth
            name="comments"
            label={t('Comentaris')}
            variant="outlined"
            multiline
            rows="4"
            value={comments}
            onChange={this.handleChange('comments')}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="secondary"
            name="cancel"
            onClick={this.handleClose}
          >
            {t('Cancelar')}
          </Button>
          <Button
            size="small"
            color="primary"
            name="create"
            disabled={!this.isValidForm()}
            onClick={this.createOffer}
            data-cy="createOffer"
          >
            {offerToEdit ? t('Edit') : t('Crear')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  filter: state.temp.offersFilter,
  // eslint-disable-next-line no-nested-ternary
  possibleHospitals: state.hospitals.length === 0
    ? [] : (state.hospitals[0].type === 'OFFERER'
      ? ([/*({ _id: 'all', name: 'All', bedTypes: '' }),*/ ...(state.hospitals[0].source)])
      : ([/*({ _id: 'all', name: 'All', bedTypes: '' }),*/ ...(state.hospitals[0].target)])),
  possibleBedTypes: (state.hospitals[0].bedTypes)
  // eslint-disable-next-line no-nested-ternary
  // possibleBedTypes: state.hospitals[0].type === 'OFFERER'
  //   ? (state.hospitals[0].bedTypes)
  //   : (_.uniq(_.flattenDeep(state.hospitals[0].target.map((bedT) => (bedT.bedTypes)))))
})

export default withStyles(styles)(withTranslation()(
  connect(mapStateToProps)(AddOfferModal)
))
