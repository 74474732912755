import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withAppContext } from '../../lib/context'
import MainLayout from './MainLayout'
import Column from './Column'
import Filters from '../offers/Filters'
import AddOfferModal from '../offers/AddOfferModal'
import CreateRequestButton from '../offers/CreateRequestButton'
import Snackbar from '../../containers/Snackbar'
import { offersFilter } from '../../lib/utils'
import { HOSPITAL_TYPES } from '../../constants/hospitals'
import { OFFER_TYPES } from '../../constants/offers'
import NoDataNote from '../offers/NoDataNote'
import OfferNote from '../offers/OfferNote'
import RequestNote from '../offers/RequestNote'
import AcceptedOfferNote from '../offers/AcceptedOfferNote'
import AcceptedRequestNote from '../offers/AcceptedRequestNote'
import IconoBuscarCama from '../../assets/BUSCAR-CAMA-VERDE.png'
import IconoOfrecerCama from '../../assets/OFRECER-CAMA-VERDE.png'
import IconoSolicitarCama from '../../assets/SOLICITAR-CAMA-VERDE.png'
import IconoAceptarCama from '../../assets/ACEPTAR-CAMA-VERDE.png'

const MainView = ({
  filter,
  setFilter,
  notifications,
  activeOffers,
  acceptedOffers,
  createOffer,
  editOffer,
  hospital,
  dismissNotifications
}) => {
  if (!hospital) return null
  const [offerToEdit, setOfferToEdit] = useState()
  const [editOfferOpen, setEditOfferOpen] = useState(false)

  const { t } = useTranslation()

  const mainView = (
    <MainLayout
      hospital={hospital}
      notifications={notifications}
      columns={[
        (
          <Column
            tourId="filterColumn"
            title={<><span>&nbsp;&nbsp;&nbsp;<img src={IconoBuscarCama} height="30" alt="logo" /></span><span color="#456553">&nbsp;&nbsp;&nbsp;{t('main.filters')}</span></>}
            key="filters"
          >
            <Filters defaultFilter={filter} sendChange={setFilter} />
          </Column>
        ),
        (
          <Column
            activeTimeInterval={hospital.activeTimeInterval}
            tourId="offersColumn"
            key="activeOffer"
            title={<><span>&nbsp;&nbsp;&nbsp;<img src={IconoOfrecerCama} height="30" alt="logo" /></span><span color="#456553">&nbsp;&nbsp;&nbsp;{t('main_offers')} (
              {
                activeOffers
                  .filter(offersFilter(filter))
                  .filter(item => item.type === OFFER_TYPES.OFFER)
                  .reduce((prev, cur) => prev + Number(cur.numBeds), 0)
              })</span></>}
            FabModal={AddOfferModal}
            showFabButton={!hospital.type || hospital.type !== HOSPITAL_TYPES.REQUESTER}
            createOfferHandler={offer => createOffer(offer, hospital, notifications)}
            NoDataComponent={
              hospital.type === !HOSPITAL_TYPES.OFFERER && NoDataNote
            }

          >
            {activeOffers.filter(offersFilter(filter)).map(item => (
              item.type === OFFER_TYPES.OFFER
                ? (
                  <OfferNote
                    key={item._id}
                    item={item}
                    notifications={notifications}
                    dismissNotifications={dismissNotifications}
                    activeTimeInterval={hospital.activeTimeInterval}
                    onEditOffer={(offer) => {
                      setOfferToEdit(offer)
                      setEditOfferOpen(true)
                    }}
                  />)
                : null
            ))}
          </Column>
        ),
        (
          <Column
            tourId="requestsColumn"
            key="activeRequest"
            title={<><span>&nbsp;&nbsp;&nbsp;<img src={IconoSolicitarCama} height="30" alt="logo" /></span><span color="#456553">&nbsp;&nbsp;&nbsp;{t('main_requests')} ({
              activeOffers
                .filter(offersFilter(filter))
                .filter(item => item.type === OFFER_TYPES.REQUEST)
                .reduce((prev, cur) => prev + Number(cur.numBeds), 0)
            })</span></>}
            FabModal={AddOfferModal}
            createOfferHandler={offer => createOffer(offer, hospital, notifications)}
            NoDataComponent={
              hospital.type === !HOSPITAL_TYPES.REQUESTER && NoDataNote
            }
            AddComponent={
              hospital.type === HOSPITAL_TYPES.REQUESTER && CreateRequestButton
            }
            offerToEdit={offerToEdit}
          >
            {activeOffers.filter(offersFilter(filter)).map(item => (
              item.type === OFFER_TYPES.REQUEST
                ? (
                  <RequestNote
                    key={item._id}
                    item={item}
                    notifications={notifications}
                    dismissNotifications={dismissNotifications}
                    onEditOffer={(offer) => {
                      setOfferToEdit(offer)
                      setEditOfferOpen(true)
                    }} />)
                : null
            ))}
          </Column>
        ),
        (
          <Column
            acceptedColumn
            tourId="acceptedColumn"
            key="accepted"
            title={<><span>&nbsp;&nbsp;&nbsp;<img src={IconoAceptarCama} height="30" alt="logo" /></span><span color="#456553">&nbsp;&nbsp;&nbsp;{t('main.accepted_offers_requests')}</span></>}
          >
            {acceptedOffers.map(item => (
              // eslint-disable-next-line no-nested-ternary
              (item.acceptor && (item.acceptor._id === hospital._id || item.isOwner)) ? (
                item.type === OFFER_TYPES.REQUEST
                  ? <AcceptedRequestNote key={item._id} item={item} />
                  : <AcceptedOfferNote key={item._id} item={item} />
              ) : null
            ))}
          </Column>
        )
      ]}
    />
  )

  return (
    <div>
      {mainView}
      {editOfferOpen && (
        <AddOfferModal
          title={`${t('Edit')} ` + `${t(offerToEdit.type)}`.toLocaleLowerCase()}
          open={editOfferOpen}
          offerType={offerToEdit.type}
          handleClose={() => setEditOfferOpen(false)}
          createOfferHandler={(offer) => {
            //createOfferHandler({ type: offerType, ...offer })
            editOffer(offer, hospital)
            setEditOfferOpen(false)
          }}
          offerToEdit={offerToEdit}
        />
      )}
      <Snackbar />
    </div>
  )
}

export default withAppContext(MainView)
