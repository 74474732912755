import { omit } from 'lodash'
import {
  STATE_ACCEPTED, STATE_CANCELED, STATE_ACTIVE, STATE_CONFIRMED, STATE_COMPLETED, STATE_REJECTED,
  STATE_DISCHARGED, STATE_REJECTED_AND_DELETED, STATE_ACCEPTED_REJECTED
} from '../constants/offers'

export const acceptOffer = (state, action, notifications) => {
  let newOffer = null
  const newState = state.map(o => {
    if (o._id !== action.offer._id) {
      return o
    }
    if (o.numBeds > 1) {
      newOffer = {
        ...omit(o, 'newId'), numBeds: o.numBeds - 1, _id: action.offer.newId, oldId: action.offer._id
      }
    }
    return {
      ...action.offer,
      numBeds: 1,
      state: STATE_ACCEPTED,
      acceptor: action.hospital,
      log: action.offer.type === 'REQUEST'
        ? updateLog(action.offer, { hospitalId: action.hospital._id }, 'Sol·licitud Acceptada', 'Sol·licitud Acceptada')
        : updateLog(action.offer, { hospitalId: action.hospital._id }, 'Oferta Acceptada', 'Oferta Acceptada')
    }
  })
  if (newOffer) {
    newState.push(newOffer)
  }
  return newState
}

export const rejectRequest = (state, action) => {
  const newState = state.map(o => {
    if (o._id !== action.offer._id) {
      return o
    }
    const modifiedOffer = {
      ...o,
      targetHospitalsAvailable: Array.isArray(action.offer.targetHospitalsAvailable)
        ? (action.offer.targetHospitalsAvailable.filter(th => th !== action.hospital._id))
        : ([action.offer.targetHospitalsAvailable].filter(th => th !== action.hospital._id)),
      rejector: o.rejector ? [...o.rejector, action.hospital._id] : [action.hospital._id]
    }
    if (modifiedOffer.targetHospitalsAvailable.length === 0) {
      modifiedOffer.state = STATE_REJECTED
      modifiedOffer.log = updateLog(action.offer, { hospitalId: action.hospital._id }, 'Sol·licitud Rebutjada', 'Sol·licitud Rebutjada')

    }

    return modifiedOffer
  })
  return newState
}

export const updateLog = (offer, action, message, title) => {
  const newLog = {
    hospital: action.hospitalId,
    message,
    title,
    date: new Date().toJSON()
  }
  if (action.receivers) {
    newLog.receivers = action.receivers
  }
  return [...(offer.log || []), newLog]
}

export const cancelOffer = (offer, hospital, cancelReason) => ({
  ...offer,
  state: STATE_CANCELED,
  cancelReason,
  log: offer.type === 'REQUEST'
    ? updateLog(offer, { hospitalId: hospital._id }, 'Sol·licitud Cancel·lada', 'Sol·licitud Cancel·lada')
    : updateLog(offer, { hospitalId: hospital._id }, 'Oferta Cancel·lada', 'Oferta Cancel·lada')
})

export const cancelAccepted = (offer, hospital, cancelReason) => ({
  ...offer,
  state: STATE_ACTIVE,
  cancelReason,
  log: offer.type === 'REQUEST'
    ? updateLog(offer, { hospitalId: hospital._id }, 'Acceptació de sol·licitud CANCEL·LADA', 'Acceptació de sol·licitud CANCEL·LADA')
    : updateLog(offer, { hospitalId: hospital._id }, 'Acceptació d\'oferta CANCEL·LADA', 'Acceptació d\'oferta CANCEL·LADA')
})

export const rejectAccepted = (offer, hospital, cancelReason) => ({
  ...offer,
  state: STATE_ACCEPTED_REJECTED,
  cancelReason,
  log: offer.type === 'REQUEST'
    ? updateLog(offer, { hospitalId: hospital._id }, 'Acceptació de sol·licitud REBUTJADA', 'Acceptació de sol·licitud REBUTJADA')
    : updateLog(offer, { hospitalId: hospital._id }, 'Acceptació d\'oferta REBUTJADA', 'Acceptació d\'oferta REBUTJADA')
})

export const confirmOffer = (offer, hospital) => ({
  ...offer,
  confirmedDate: new Date().toJSON(),
  state: STATE_CONFIRMED,
  log: updateLog(offer, { hospitalId: hospital._id }, 'Offer/Request Confirmed', 'Offer/Request Confirmed')
})

export const completeOffer = (offer, hospital) => ({
  ...offer,
  completedDate: new Date().toJSON(),
  state: STATE_COMPLETED,
  log: updateLog(offer, { hospitalId: hospital._id }, 'Derivació finalitzada', 'Derivació finalitzada')
})
export const dischargeOffer = (offer, hospital) => ({
  ...offer,
  dischargedDate: new Date().toJSON(),
  state: STATE_DISCHARGED,
  log: updateLog(offer, { hospitalId: hospital._id }, 'Pacient donat d’alta', 'Pacient donat d’alta')
})
export const deleteRejected = (offer, hospital) => ({
  ...offer,
  deletedDate: new Date().toJSON(),
  state: STATE_REJECTED_AND_DELETED
  // eslint-disable-next-line max-len
  // log: updateLog(offer, { hospitalId: hospital._id }, 'Sol·licitud rebutjada eliminada', 'Sol·licitud rebutjada y eliminada')
})


const getCommentReceivers = (offer, hospital) => {
  if (offer.type === 'REQUEST') {
    if (hospital.type === 'REQUESTER') {
      return offer.targetHospitals
    }
    return [offer.sourceHospitals]
  }
  if (hospital.type === 'REQUESTER') {
    return [offer.sourceHospitals]
  }
  return offer.targetHospitals
}

export const commentRequest = (offer, { hospital, newComment, date }) => ({
  ...offer,
  commentsRequested: [
    ...(offer.commentsRequested || []),
    {
      hospitalId: hospital._id,
      hospitalName: hospital.name,
      comment: newComment, date,
      receivers: getCommentReceivers(offer, hospital)
    }
  ],
  // state: STATE_COMMENTED,
  log: offer.type === 'REQUEST'
    ? updateLog({
      ...offer,
      commentsRequested: [
        ...(offer.commentsRequested || []),
        {
          hospitalId: hospital._id,
          hospitalName: hospital.name,
          comment: newComment, date,
          receivers: getCommentReceivers(offer, hospital)
        }
      ]
    }, {
      hospitalId: hospital._id,
      receivers: getCommentReceivers(offer, hospital)
    }, 'Nou comentari a la sol·licitud', 'Nou comentari a la sol·licitud')
    : updateLog({
      ...offer,
      commentsRequested: [
        ...(offer.commentsRequested || []),
        {
          hospitalId: hospital._id,
          hospitalName: hospital.name,
          comment: newComment,
          receivers: getCommentReceivers(offer, hospital),
          date
        }
      ]
    }, {
      hospitalId: hospital._id,
      receivers: getCommentReceivers(offer, hospital)
    }, 'Nou comentari a la oferta', 'Nou comentari a la oferta')
})
