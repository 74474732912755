import i18n from 'i18next'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'
import Locize from 'i18next-locize-backend'
// import resources from './translations'

const defaultLng = 'ca'
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Locize)
  .init({
    // resources,
    // debug: true,
    fallbackLng: ['en', 'es', 'ca'], // FIXME I need to load all languages before hand
    lng: window.localStorage.getItem('lng') || defaultLng,
    defaultNS: 'default',
    saveMissing: !!process.env.REACT_APP_LOCIZE_API_KEY,
    interpolation: {
      // react already safes from xss,
      escapeValue: false,
      format: (value, format) => {
        switch (format) {
          case 'uppercase':
            return value.toUpperCase()
          case 'lowercase':
            return value.toLowerCase()
          case 'datetime':
            // TODO: use a predefined constant or a local datetime format
            return moment(value).format('HH:mm (DD/MM/YYYY)')
          default:
            return value
        }
      }
    },
    backend: {
      projectId: '07c84ea5-08c3-4f0b-9076-b378725ece9f',
      referenceLng: 'en',
      apiKey: process.env.REACT_APP_LOCIZE_API_KEY
    }
  })

export default i18n
