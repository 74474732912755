export const CREATE_OFFER = 'CREATE_OFFER'
export const EDIT_OFFER = 'EDIT_OFFER'
export const ACCEPT_OFFER = 'ACCEPT_OFFER'
export const CONFIRM_OFFER = 'CONFIRM_OFFER'
export const COMPLETE_OFFER = 'COMPLETE_OFFER'
export const DISCHARGE_OFFER = 'DISCHARGE_OFFER'
export const CANCEL_OFFER = 'CANCEL_OFFER'
export const DELETE_OFFER = 'DELETE_OFFER'
export const CANCEL_ACCEPTED = 'CANCEL_ACCEPTED'
export const REJECT_ACCEPTED = 'REJECT_ACCEPTED'
export const REJECT_REQUEST = 'REJECT_REQUEST'
export const STATE_CANCELED = 'CANCELED'
export const STATE_ACTIVE = 'ACTIVE'
export const STATE_ACCEPTED = 'ACCEPTED'
export const STATE_CONFIRMED = 'CONFIRMED'
export const STATE_COMPLETED = 'COMPLETED'
export const STATE_REJECTED = 'REJECTED'
export const STATE_DISCHARGED = 'DISCHARGED'
export const STATE_REJECTED_AND_DELETED = 'REJECTED_DELETED'
export const STATE_ACCEPTED_REJECTED = 'ACCEPTED_REJECTED'
export const STATE_COMMENTED = 'COMMENTED'
export const SET_FILTER = 'SET_FILTER'
export const COMMENT_REQUEST = 'COMMENT_REQUEST'
export const OFFER_TYPES = {
  OFFER: 'OFFER',
  REQUEST: 'REQUEST'
}

export const OFFER_CANCEL_REASONS = {
  ADMINISTRATIVE_ERROR: 'Error administratiu',
  ASSITANCE_REQUIREMENTS: 'Requeriments assistencials',
  FACULTATIVE_DECISION: 'Decisió facultatiu',
  HAE_INDICATION: 'Indicació HAE',
  OFFER_NOT_COVERED: 'Oferta no coberta'
}

export const REQUEST_CANCEL_REASONS = {
  ADMINISTRATIVE_ERROR: 'Error administratiu',
  FACULTATIVE_DECISION: 'Decisió facultatiu',
  RECIEVED_OFFER: 'Oferta rebuda'
}
