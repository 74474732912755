import React, { useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import {
  cancelAccepted, rejectAccepted, completeOffer, dischargeOffer
} from '../../actions/offers'
import Note from './Note'
import { OFFER_TYPES } from '../../constants/offers'
import CancelReason from '../dialogs/CancelReason'

const AcceptedRequestNote = ({
  t, hospital, cancelA, rejectA, complete, discharge, item
}) => {
  const {
    bedType, state, gender, covid, isolation, patientId, date, creationDate, acceptDate, comments,
    commentsOffered, commentsRequested, acceptor, arrivalTime, dischargedDate, vaccinated,
    involvedHospitals: [hospitalId]
  } = item
  const [handleRejectAccepted, setHandleRejectAccepted] = useState(false)
  const [handleCancelAccepted, setHandleCancelAccepted] = useState(false)
  const [handleDischarge, setHandleDischarge] = useState(false)
  const [handleComplete, setHandleComplete] = useState(false)
  const originName = getHospitalNameById(hospital, hospitalId)
  const [cancelReason, setCancelReason] = useState('')

  const acceptorName = acceptor.name
  const isOwner = hospital._id === hospitalId
  // const couldCancelAccepted = hospital._id === acceptor._id
  const arrivalT = moment(arrivalTime).format('HH:mm (DD/MM/YYYY)')
  const dischargedT = moment(dischargedDate).format('HH:mm (DD/MM/YYYY)')
  const title = isOwner ? (
    <>
      {t('acceptedRequestNote.title', { bedType, gender })} {covid !== 'noCovid'
        ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
          ? <strong>{t('isolated')}</strong> : ''}
      <>&nbsp;</><>&#8649;</><>&nbsp;</>
      <strong>{acceptorName}</strong>
    </>
  ) : (
    <>
      {t('acceptedRequestNote.title', { bedType, gender })} {covid !== 'noCovid'
        ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
          ? <strong>{t('isolated')}</strong> : ''}
      <>&nbsp;</><>&#8647;</><>&nbsp;</>
      {/* &laquo; */}
      <strong>{originName}</strong>
    </>
  )
  const subheader = state === 'DISCHARGED' ? (
    <div data-cy="dischargedRequestNote">
      <Trans i18nKey="note.patientId">
        Patient ID: <strong> {{ patientId }} </strong>
      </Trans>
      <div>
        {vaccinated && t('Vaccinated')}
      </div>
      <div> <strong style={{ color: '#003300' }}>{t('DISCHARGED')}</strong>&nbsp;{t('at')}&nbsp;{dischargedT}</div>
    </div>

  ) : (
    <div data-cy="acceptedRequestNote">
      <div title={moment(date).fromNow()}>{t('acceptedRequestNote.subheader', { date })}</div>
      <Trans i18nKey="note.patientId">
        Patient ID: <strong> {{ patientId }} </strong>
      </Trans>
      <div>
        {vaccinated && t('Vaccinated')}
      </div>
      <div title={moment(arrivalTime).fromNow()}>{t('Available at')}&nbsp;{arrivalT}</div>
    </div>
  )

  const canComplete = (hospital.type === 'OFFERER' && state === 'DISCHARGED') && (
    <Button
      data-cy="acceptedRequestCompleteButton"
      color="primary"
      onClick={() => setHandleComplete(true)}
    >
      {t('COMPLETE')}
    </Button>
  )

  const canDischarge = (hospital.type === 'REQUESTER' && state === 'ACCEPTED') && (
    <Button
      data-cy="acceptedRequestDischargeButton"
      color="primary"
      onClick={() => setHandleDischarge(true)}
    >
      {t('DISCHARGE')}
    </Button>
  )
  const canRejectAccepted = isOwner && state === 'ACCEPTED' && (
    <Button
      data-cy="acceptedRequestCancelButton"
      color="secondary"
      onClick={() => setHandleRejectAccepted(true)}
    >
      {t('Cancelar')}
    </Button>
  )
  // const canCancelAccepted = couldCancelAccepted && state === 'ACCEPTED' && (
  //   <Button
  //     data-cy="acceptedRequestRejectButton"
  //     color="secondary"
  //     onClick={() => setHandleCancelAccepted(true)}
  //   >
  //     {t('Cancelar')}
  //   </Button>
  // )

  const actions = (
    <>
      {canComplete}
      {canDischarge}
      {canRejectAccepted}
      {/* {canCancelAccepted} */}
    </>
  )

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)

  return (
    <>
      <Note
        type={OFFER_TYPES.REQUEST}
        raised={isOwner}
        gender={gender}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        actions={actions}
        item={item}
        state={state}
        bedType={bedType}
      />
      <Dialog
        open={handleRejectAccepted}
        onClose={() => setHandleRejectAccepted(false)}
      >
        <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
        <DialogContent>
          <CancelReason type={OFFER_TYPES.REQUEST} value={cancelReason} onChange={setCancelReason} />
          <DialogContentText>
            {t('cancel_request_alert_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleRejectAccepted(false)} color="primary">
            No
          </Button>
          <Button onClick={() => rejectA(item, hospital, cancelReason)} color="primary" disabled={cancelReason.length === 0}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={handleCancelAccepted}
        onClose={() => setHandleCancelAccepted(false)}
      >
        <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('reject_request_alert_title', { patientId })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleCancelAccepted(false)} color="primary">
            No
          </Button>
          <Button onClick={() => cancelA(item, hospital)} color="primary">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={handleDischarge}
        onClose={() => setHandleDischarge(false)}
      >
        <DialogTitle>{t('discharge_alert_title', { patientId })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('discharge_text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleDischarge(false)} color="primary">
            No
          </Button>
          <Button onClick={() => discharge(item, hospital)} color="primary" data-cy="acceptDischarge">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={handleComplete}
        onClose={() => setHandleComplete(false)}
      >
        <DialogTitle>{t('complete_alert_title', { patientId })}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('complete_alert_text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleComplete(false)} color="primary">
            No
          </Button>
          <Button onClick={() => complete(item, hospital)} color="primary" data-cy="acceptComplete">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  hospital: state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : null
})
const mapDispatchToProps = dispatch => ({
  rejectA: (offer, hospital, reason) => dispatch(rejectAccepted(offer, hospital, reason)),
  cancelA: (offer, hospital) => dispatch(cancelAccepted(offer, hospital)),
  complete: (offer, hospital) => dispatch(completeOffer(offer, hospital)),
  discharge: (offer, hospital) => dispatch(dischargeOffer(offer, hospital))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(AcceptedRequestNote)
)
