import moment from 'moment'
import { takeLatest, select, put } from 'redux-saga/effects'
import { QUERY_SYNC } from '../constants/redux-firestore-sync'
import {
  STATE_ACTIVE, STATE_ACCEPTED, STATE_CONFIRMED, STATE_REJECTED, STATE_DISCHARGED, STATE_CANCELED,
  STATE_COMPLETED, STATE_REJECTED_AND_DELETED, STATE_ACCEPTED_REJECTED, STATE_COMMENTED
} from '../constants/offers'
import { SWITCH_HOSPITAL } from '../constants/firebase'

const marketCollections = {
  activeOffers: [{
    collection: 'offers',
    where: [['state', '==', STATE_ACTIVE], ['involvedHospitals', 'array-contains', ':_id']]
  },
  {
    collection: 'offers',
    where: [['state', '==', STATE_REJECTED], ['involvedHospitals', 'array-contains', ':_id']]
  },
  {
    collection: 'offers',
    where: [['state', '==', STATE_COMMENTED], ['involvedHospitals', 'array-contains', ':_id']]
  }],
  acceptedOffers: [{
    collection: 'offers',
    where: [['state', '==', STATE_ACCEPTED], ['involvedHospitals', 'array-contains', ':_id']]
  },
  {
    collection: 'offers',
    where: [['state', '==', STATE_DISCHARGED], ['involvedHospitals', 'array-contains', ':_id']]
  }],
  confirmedOffers: [{
    collection: 'offers',
    where: [['state', '==', STATE_CONFIRMED], ['confirmedDate', '>=', ':confirmedDate'], ['involvedHospitals', 'array-contains', ':_id']]
  }],
  canceledOffers: [{
    collection: 'offers',
    where: [['state', '==', STATE_CANCELED], ['involvedHospitals', 'array-contains', ':_id']],
    limit: 10,
    orderBy: ['date', 'desc']
  },
  {
    collection: 'offers',
    where: [['state', '==', STATE_ACCEPTED_REJECTED], ['involvedHospitals', 'array-contains', ':_id']],
    limit: 10,
    orderBy: ['date', 'desc']
  }],
  completedOffers: [{
    collection: 'offers',
    where: [['state', '==', STATE_COMPLETED], ['involvedHospitals', 'array-contains', ':_id']],
    limit: 10,
    orderBy: ['date', 'desc']
  }],
  rejectedDeletedOffers: [{
    collection: 'offers',
    where: [['state', '==', STATE_REJECTED_AND_DELETED], ['involvedHospitals', 'array-contains', ':_id']]
  }],
  notifications: [{
    collection: 'notifications',
    where: [['hospital', '==', ':_id']],
    limit: 100,
    orderBy: ['date', 'desc']
  }]
}

function * syncData (action) {
  const yday = moment().subtract(24, 'h').toJSON()
  const hospitals = yield select(state => state.hospitals)
  yield put({
    type: QUERY_SYNC,
    queries: marketCollections,
    params: { _id: hospitals[0]._id, confirmedDate: yday }
  })
}

export const watchSwitchHospital = takeLatest(SWITCH_HOSPITAL, syncData)
