import React from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/HelpOutline'
import PersonIcon from '@material-ui/icons/Person'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'
import NotificationsIcon from '@material-ui/icons/Notifications'
import HistoryIcon from '@material-ui/icons/History'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import { withAppContext } from '../../lib/context'
import IdPortal from '../shared/IdPortal'

const styles = theme => ({
  helpButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
})

const ToolbarButtons = ({
  setTourOpen, classes, t, notifications, onExit, onOpenUserPreferences,
  onOpenNotifications, onOpenHistory
}) => {
  const unreadNotifications = notifications.filter(item => !item.read).length
  return (
    <IdPortal portalId="portal-tools">
      <IconButton
        data-tour="userSettings"
        color="inherit"
        title={t('toolbar.user_button_title')}
        onClick={onOpenUserPreferences}
      >
        <PersonIcon />
      </IconButton>
      <IconButton
        data-tour="historyAccess"
        color="inherit"
        title={t('toolbar.history_button_title')}
        component={Link}
        to="/history"
      ><HistoryIcon />
      </IconButton>
      <IconButton
        data-tour="notificationsAccess"
        color="inherit"
        title={t('toolbar.user_notifications')}
        onClick={onOpenNotifications}
        data-cy="notificationIcon"
      >
        <Badge badgeContent={unreadNotifications} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <IconButton
        className={classes.helpButton}
        color="inherit"
        title={t('toolbar.help_button_title')}
        onClick={() => setTourOpen(true)}
      >
        <HelpIcon />
      </IconButton>
      <IconButton
        color="inherit"
        title={t('toolbar.exit_button_title')}
        onClick={onExit}
      >
        <ExitIcon />
      </IconButton>
    </IdPortal>
  )
}

export default withAppContext(withStyles(styles)(withTranslation()(ToolbarButtons)))
