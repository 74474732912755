export const BED_TYPES_ACR = {
  acute: 'ACU',
  subacute: 'SAG',
  convalescent: 'CNV',
  longstay: 'LLE',
  paliative: 'PAL',
  mental_health: 'PSQ',
  UHPAF: 'UHP',
  covid19: 'CoV'
}
