import React, { useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import ShowMoreIcon from '@material-ui/icons/KeyboardArrowRight'
import ShowLessIcon from '@material-ui/icons/KeyboardArrowDown'
// import AcUnitIcon from '@material-ui/icons/AcUnit'
// import BedIcon from '@material-ui/icons/AirlineSeatFlat'
import ShareIcon from '@material-ui/icons/Share'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { PALETTE } from '../../constants/palette'
import { BED_TYPES_ACR } from '../../constants/bed-type-acr'

const styles = theme => {
  const unit = theme.spacing(1)
  const { grey } = theme.palette
  const { CARD } = PALETTE
  const { TYPE, GENDER } = CARD

  return {
    root: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      }
    },
    header: {
      padding: `${2 * unit}px 0 ${2 * unit}px ${3 * unit}px`,
      marginBottom: 3 * unit
    },
    titleAndSubtitle: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        marginBottom: unit
      }
    },
    avatar: {
      marginRight: 2 * unit,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: unit
      },
      fontSize: 15,
      fontWeight: 'bold'
    },
    headBtn: {
      marginTop: -4 * unit,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      }
    },
    actions: {
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        justifyContent: 'center'
      }
    },
    offerType: {
      backgroundColor: TYPE.OFFER
    },
    requestType: {
      backgroundColor: TYPE.REQUEST
    },
    male_isolatedAvatar: {
      backgroundColor: GENDER.MALE,
      border: `5px solid ${grey['400']}`
    },
    femaleisolatedAvatar: {
      backgroundColor: GENDER.FEMALE,
      border: `5px solid ${grey['400']}`
    },
    indifferentisolatedAvatar: {
      backgroundColor: GENDER.INDIFFERENT,
      border: `5px solid ${grey['400']}`
    },
    male_noIsolatedAvatar: {
      backgroundColor: GENDER.MALE
    },
    femalenoIsolatedAvatar: {
      backgroundColor: GENDER.FEMALE
    },
    indifferentnoIsolatedAvatar: {
      backgroundColor: GENDER.INDIFFERENT
    }
  }
}

const OfferDialog = ({
  classes, t, onClose, type, bedType, raised, gender, isolation, title, subheader, content, messages, actions, newEvent
}) => {
  const [expandComments, setExpandComments] = useState(false)
  const messagesLen = _.size(messages)
  const typeClass = classes[`${_.toLower(type)}Type`]
  const avatarClass = classes[`${gender}${isolation}Avatar`]

  return (
    <Dialog open onClose={onClose} className={classes.root}>
      <DialogTitle className={clsx(classes.header, typeClass)} disableTypography>
        <Grid container alignItems="center" className={classes.titleAndSubtitle}>
          <Grid item>
            <Avatar className={clsx(classes.avatar, avatarClass)}>
              {t(BED_TYPES_ACR[bedType])}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h6" className={classes.title}>{title}</Typography>
            <Typography>{subheader}</Typography>
          </Grid>
          <Grid item>
            <CopyToClipboard text={document.location.href}>
              <IconButton
                title={t('Copy to clipboard')}
                className={classes.headBtn}
                onClick={() => newEvent(t('Copied to clipboard'))}
              >
                <ShareIcon />
              </IconButton>
            </CopyToClipboard>
          </Grid>
          <Grid item>
            <IconButton className={classes.headBtn} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {actions}
        {messagesLen > 0 && (
          <Button data-cy="commentsButton" onClick={() => { setExpandComments(!expandComments) }}>
            {t('comments')}
            {expandComments ? <ShowLessIcon /> : <ShowMoreIcon />}
          </Button>
        )}
      </DialogActions>
      <Collapse in={expandComments} timeout="auto">
        <DialogContent>
          {messages && messages.map((message, i) => {
            const { name, text, date } = message

            return (
              <div key={i}>
                <Typography title={moment(date).format('HH:mm (DD/MM/YYYY)')} variant="body2" color="textSecondary">{moment(date).fromNow()} <strong>{name}</strong> {t('note.wrote')}:</Typography>
                <Typography data-cy="comments" paragraph={i < messagesLen - 1}>{text}</Typography>

              </div>
            )
          })}
        </DialogContent>
      </Collapse>
    </Dialog>
  )
}

export default withStyles(styles)(withTranslation()(OfferDialog))
