import React, { useState } from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import OfferDialog from './OfferDialog'
import { OFFER_TYPES } from '../../constants/offers'
import { getHospitalNameById, filterComments } from '../../lib/utils'

const DischargedOfferDialog = ({
  t, classes, hospital, item, complete, onClose, newEvent
}) => {
  const {
    _id, bedType, gender, covid, isolation, patientId, numBeds, date, creationDate, acceptDate,
    comments, commentsOffered, commentsRequested, dischargedDate, acceptor,
    involvedHospitals: [hospitalId]
  } = item
  const [handleComplete, setHandleComplete] = useState(false)
  const originName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  const acceptorName = acceptor.name
  const dischargedT = moment(dischargedDate).format('HH:mm (DD/MM/YYYY)')
  // const title = t('acceptedOfferNote.title', { count: numBeds, bedType, gender })
  const title = (
    <span>{t('acceptedOfferNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
      ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  const subheader = t('acceptedOfferNote.subheader', { date })
  const isOwner = hospital._id === hospitalId

  const completeAndClose = () => {
    complete(item, hospital)
    onClose()
  }

  const content = isOwner ? (
    <div data-cy="dischargedRequestNote">
      <Trans i18nKey="note.patientId">
      Patient ID: <strong> {{ patientId }} </strong>
      </Trans>
      <div> <strong>{t('DISCHARGED')}</strong>&nbsp;{t('at')}&nbsp;{dischargedT}&nbsp;{t('from')} <strong>{acceptorName}</strong></div>
    </div>
  ) : (
    <div data-cy="dischargedRequestNote">
      <Trans i18nKey="note.patientId">
      Patient ID: <strong> {{ patientId }} </strong>
      </Trans>
      <div> <strong>{t('DISCHARGED')}</strong>&nbsp;{t('at')}&nbsp;{dischargedT}&nbsp;{t('to')} <strong>{originName}</strong></div>
    </div>
  )

  const actions = isOwner ? (
    <Button
      color="primary"
      onClick={() => setHandleComplete(true)}
    >
      {t('COMPLETE')}
    </Button>
  ) : (
    null
  )

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)

  return (
    _id && (
      <>
        <OfferDialog
          onClose={onClose}
          type={OFFER_TYPES.OFFER}
          gender={gender}
          bedType={bedType}
          covid={covid}
          isolation={isolation}
          title={title}
          subheader={subheader}
          messages={messages}
          content={content}
          actions={actions}
          newEvent={newEvent}
        />
        <Dialog
          open={handleComplete}
          onClose={() => setHandleComplete(false)}
        >
          <DialogTitle>{t('complete_alert_title', { patientId })}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('complete_alert_text')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleComplete(false)} color="primary">
          No
            </Button>
            <Button onClick={() => completeAndClose(item, hospital)} color="primary" data-cy="acceptComplete">
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

export default withTranslation()(DischargedOfferDialog)
