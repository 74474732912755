import React, { useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import {
  acceptOffer, cancelOffer, rejectRequest, deleteRejected, commentRequest
} from '../../actions/offers'
import Note from './Note'
// import { dismissNotifications } from '../../actions/notifications'
import AcceptOfferModal from './AcceptOfferModal'
import CommentRequestModal from './CommentRequestModal'
import { OFFER_TYPES } from '../../constants/offers'
import OfferList from './OfferList'
import CancelReason from '../dialogs/CancelReason'

const RequestNote = ({
  t, hospital, accept, cancel, rejectR, deleteR, commentR, item, notifications, dismissNotifications, onEditOffer
}) => {
  const [open, setOpen] = useState(false)
  const [openRequestComment, setOpenRequestComment] = useState(false)
  const [handleCancel, setHandleCancel] = useState(false)
  const [handleReject, setHandleReject] = useState(false)
  const [handleDelete, setHandleDelete] = useState(false)
  const [cancelReason, setCancelReason] = useState('')

  const {
    bedType, state, gender, covid, isolation, numBeds, patientId, date: availTime, comments,
    creationDate, commentsRequested, targetHospitals, targetHospitalsNames, rejector, involvedHospitals: [hospitalId],
    priority, passive, vaccinated
  } = item
  const hospitalName = getHospitalNameById(hospital, hospitalId)
  const targetHospitalName = Array.isArray(targetHospitals) ? (
    targetHospitals.map((targetHospital) => (getHospitalNameById(hospital, targetHospital)))
  ) : ([targetHospitals].map((targetHospital) => (getHospitalNameById(hospital, targetHospital))))
  const targetName = targetHospitalsNames ? (targetHospitalsNames.map((name) => <li key={name}>{name}</li>)) : ''
  const rejectorName = rejector ? (
    rejector.map((reject) => (getHospitalNameById(hospital, reject)))
  ) : ('noRejected')

  const bedNumbers = parseInt(numBeds, 10)
  const subheader = state === 'REJECTED' ? (
    <>
      <div style={{ color: '#cf6d67' }} data-cy="rejectedRequestNote">
        <div title={moment(availTime).fromNow()}>{t('requestNote.subheader', { availTime })}</div>
        <Trans i18nKey="note.patientId">
          Patient ID: <strong> {{ patientId }} </strong>
        </Trans>
        <div>
          {vaccinated && t('Vaccinated')}
        </div>
      </div>
    </>
  )
    : (
      <>
        <div data-cy="activeRequestNote">
          <div title={moment(availTime).fromNow()}>{t('requestNote.subheader', { availTime })}</div>
          <Trans i18nKey="note.patientId">
            Patient ID: <strong> {{ patientId }} </strong>
          </Trans>
          <div>
            {vaccinated && t('Vaccinated')}
          </div>
        </div>
      </>
    )

  const isOwner = hospital._id === hospitalId
  const len = targetHospitalName.length
  let content = ''
  let title = t('requestNote.title', { count: bedNumbers, bedType, gender })
  if (isOwner) {
    if (len > 1) {
      title = state === 'REJECTED' ? (
        <>
          <div data-cy="rejectedRequestNoteTitle" style={{ fontWeight: '900', textTransform: 'uppercase' }}>{t('rejected')}</div>
          <div style={{ color: '#cf6d67' }}>
            {t('requestNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
              ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
                ? <strong>{t('isolated')}</strong> : ''}
            <>&nbsp;</><>&#8649;</><>&nbsp;</>
            <strong>{t('various')}</strong>
          </div>
        </>
      )
        : (
          <>
            {t('requestNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
              ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
                ? <strong>{t('isolated')}</strong> : ''}
            <>&nbsp;</><>&#8649;</><>&nbsp;</>
            <strong>{t('various')}</strong>
          </>
        )
      content = (
        <>
          <Trans i18nKey="requestNote.textRequester" count={bedNumbers}>
            You have requested a bed in:
          </Trans>
          <OfferList
            targetHospitalName={targetHospitalName}
            rejectorName={rejectorName}
            t={t}
          />
        </>
      )
    } else {
      title = state === 'REJECTED' ? (
        <>
          <div data-cy="rejectedRequestNoteTitle" style={{ fontWeight: '900', textTransform: 'uppercase' }}>{t('rejected')}</div>
          <div style={{ color: '#cf6d67' }}>
            {t('requestNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
              ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
                ? <strong>{t('isolated')}</strong> : ''}
            <>&nbsp;</><>&#8649;</><>&nbsp;</>
            {/* &raquo; */}
            <strong>{targetHospitalName[0]}</strong>
          </div>
        </>
      )
        : (
          <>
            {t('requestNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
              ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
                ? <strong>{t('isolated')}</strong> : ''}
            <>&nbsp;</><>&#8649;</><>&nbsp;</>
            {/* &raquo; */}
            <strong>{targetHospitalName[0]}</strong>
          </>
        )
      content = (
        <>
          <Trans i18nKey="requestNote.textRequester" count={bedNumbers}>
            You have requested a bed in:
          </Trans>
          <OfferList
            targetHospitalName={targetHospitalName}
            rejectorName={rejectorName}
            t={t}
          />
        </>
      )
    }
  } else {
    title = (
      <>
        {t('requestNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
          ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
            ? <strong>{t('isolated')}</strong> : ''}
        <>&nbsp;</><>&#8647;</><>&nbsp;</>
        <strong>{hospitalName}</strong>
      </>
    )
    content = (
      <>
        <Trans i18nKey="requestNote.textRequestSendTo">
          Request send to:
        </Trans>
        <ul margin-block-start="0" type="square">
          <strong>{targetName}</strong>
        </ul>
      </>
    )
  }

  let actions = null
  if (isOwner && state !== 'REJECTED') {
    actions = (
      <>
        <Button
          data-cy="requestNoteCancelButton"
          color="secondary"
          onClick={() => setHandleCancel(true)}
        >
          {t('cancel')}
        </Button>
        <Button
          data-cy="requestNoteCommentButton"
          color="primary"
          onClick={() => setOpenRequestComment(true)}
        >
          {t('comment')}
        </Button>
        <CommentRequestModal
          title={t('add_comment')}
          offer={item}
          open={openRequestComment}
          handleClose={() => setOpenRequestComment(false)}
          commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
        />
      </>
    )
  } else if (isOwner && state === 'REJECTED') {
    actions = (
      <Button
        data-cy="requestNoteCancelButton"
        color="secondary"
        onClick={() => setHandleDelete(true)}
      >
        {t('delete')}
      </Button>
    )
  } else {
    actions = (
      <>
        <Button
          data-cy="requestNoteAcceptButton"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={passive}
        >
          {t('accept')}
        </Button>
        <AcceptOfferModal
          title={t('accept_request')}
          offer={item}
          open={open}
          handleClose={() => setOpen(false)}
          acceptOffer={(o) => accept(o, hospital)}
        />
        {/* <Button
          data-cy="requestNoteRejectButton"
          color="secondary"
          onClick={() => setHandleReject(true)}
        >
          {t('Reject')}
        </Button> */}
        <Button
          data-cy="requestNoteCommentButton"
          color="primary"
          onClick={() => setOpenRequestComment(true)}
        >
          {t('comment')}
        </Button>
        <CommentRequestModal
          title={t('add_comment')}
          offer={item}
          open={openRequestComment}
          handleClose={() => setOpenRequestComment(false)}
          commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
        />
      </>
    )
  }

  const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0).concat(newRequestComments) : [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0)

  return (
    <>
      <Note
        type={OFFER_TYPES.REQUEST}
        raised
        gender={gender}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        actions={actions}
        item={item}
        state={state}
        isOwner={isOwner}
        bedType={bedType}
        len={len}
        priority={priority}
        passive={passive}
        notifications={notifications}
        dismissNotifications={dismissNotifications}
        onClickEditButton={() => onEditOffer(item)}
      // commentR={commentR}
      />
      <Dialog
        open={handleCancel}
        onClose={() => setHandleCancel(false)}
      >
        <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
        <DialogContent>
          <CancelReason type={OFFER_TYPES.REQUEST} value={cancelReason} onChange={setCancelReason} />
          <DialogContentText>
            {t('cancel_request_alert_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleCancel(false)} color="primary">
            No
          </Button>
          <Button onClick={() => cancel(item, hospital, cancelReason)} color="primary" disabled={cancelReason.length === 0}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={handleReject}
        onClose={() => setHandleReject(false)}
      >
        <DialogTitle>{t('reject_alert_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('cancel_request_alert_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleReject(false)} color="primary">
            No
          </Button>
          <Button data-cy="rejectRequestConfirmation" onClick={() => rejectR(item, hospital)} color="primary">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={handleDelete}
        onClose={() => setHandleDelete(false)}
      >
        <DialogTitle>{t('delete_alert_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('cancel_request_alert_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleDelete(false)} color="primary">
            No
          </Button>
          <Button data-cy="rejectRequestConfirmation" onClick={() => deleteR(item, hospital)} color="primary">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  hospital: state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : null
})
const mapDispatchToProps = dispatch => ({
  accept: (offer, hospital) => dispatch(acceptOffer(offer, hospital)),
  rejectR: (offer, hospital) => dispatch(rejectRequest(offer, hospital)),
  cancel: (offer, hospital, reason) => dispatch(cancelOffer(offer, hospital, reason)),
  deleteR: (offer, hospital) => dispatch(deleteRejected(offer, hospital)),
  commentR: (newComment, offerId, hospital, date) => dispatch(commentRequest(newComment, offerId, hospital, date))
  // dismissNotifications: notifications => dispatch(dismissNotifications(notifications))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(RequestNote)
)
