import {
  CANCEL_OFFER, CANCEL_ACCEPTED, REJECT_ACCEPTED, COMPLETE_OFFER, DISCHARGE_OFFER
} from '../constants/offers'
import {
  cancelOffer, completeOffer, cancelAccepted, rejectAccepted, dischargeOffer
} from './utils'

export default (state = [], action) => {
  switch (action.type) {
    case CANCEL_OFFER:
      return state.map(o => (o._id === action.offer._id ? cancelOffer(o, action.hospital) : o))
    case CANCEL_ACCEPTED:
      // Set the existing offer to active and create a Rejected clone for history purposes.
      const targetOffer = state.find(o => o._id === action.offer._id)
      if (targetOffer) {
        const activeAgain = cancelAccepted(targetOffer, action.hospital, action.reason)
        activeAgain.patientId = undefined
        activeAgain.acceptor = undefined
        activeAgain.acceptDate = undefined
        activeAgain.arrivalTime = undefined
        activeAgain.cancelReason = undefined
        const acceptedRejected = rejectAccepted(targetOffer, action.hospital, action.reason)
        acceptedRejected._id = `${new Date().toJSON()}_${String(Math.random()).slice(2, 8)}`
        acceptedRejected.duplicatedOf = activeAgain._id
        return [...state.map(o => (o._id === action.offer._id ? activeAgain : o)), acceptedRejected]
      }
      return state
    case REJECT_ACCEPTED:
      return state.map(o => (o._id === action.offer._id ? rejectAccepted(o, action.hospital, action.reason) : o))
    case COMPLETE_OFFER:
      return state.map(o => (o._id === action.offer._id ? completeOffer(o, action.hospital) : o))
    case DISCHARGE_OFFER:
      return state.map(o => (o._id === action.offer._id ? dischargeOffer(o, action.hospital) : o))
    default:
      return state
  }
}
