import React, { useState } from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import OfferDialog from './OfferDialog'
import { OFFER_TYPES } from '../../constants/offers'
import { getHospitalNameById, filterComments } from '../../lib/utils'

const AcceptedOfferDialog = ({
  t, classes, hospital, rejectA, discharge, cancelA, item, onClose, newEvent
}) => {
  const {
    _id, bedType, gender, isolation, patientId, covid, numBeds, date, creationDate,
    acceptDate, comments, commentsOffered, commentsRequested, acceptor, arrivalTime,
    involvedHospitals: [hospitalId]
  } = item
  const [handleRejectAccepted, setHandleRejectAccepted] = useState(false)
  const [handleCancelAccepted, setHandleCancelAccepted] = useState(false)
  const [handleDischarge, setHandleDischarge] = useState(false)
  const originName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  const acceptorName = acceptor.name
  const arrivalT = moment(arrivalTime).format('HH:mm (DD/MM/YYYY)')
  const title = (
    <span>{t('acceptedOfferNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
      ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  const subheader = (<span title={moment(date).fromNow()}>{t('acceptedOfferNote.subheader', { date })}</span>)
  const isOwner = hospital._id === hospitalId

  const rejectedAcceptedAndClose = () => {
    rejectA(item, hospital)
    onClose()
  }
  const cancelAcceptedAndClose = () => {
    cancelA(item, hospital)
    onClose()
  }
  const dischargeAndClose = () => {
    discharge(item, hospital)
    onClose()
  }

  const content = (
    <>
      <Trans i18nKey="acceptedOfferNote.text">
        <strong>{{ acceptorName }}</strong> accepted your offer.&nbsp;
        {/* <strong>{{ originName }}&apos;s</strong> offer. */}
      </Trans>
      <div>
        {patientId && (
          <>
            <Trans i18nKey="note.patientId">
            Patient ID: <strong>{{ patientId }}</strong>
            </Trans>
            <div title={moment(arrivalTime).fromNow()}>{t('estimated_arrival')}&nbsp;{arrivalT}</div>
          </>
        )}
      </div>
    </>
  )

  const actions = isOwner ? (
    <>
      {/* <Button
        color="primary"
        onClick={handleConfirm}
      >
        {t('confirm')}
      </Button> */}
      {/* <Button
        color="secondary"
        onClick={() => setHandleRejectAccepted(true)}
      >
        {t('Cancelar')}
      </Button> */}
    </>
  ) : (
    <>
      <Button
        data-cy="acceptedRequestDischargeButton"
        color="primary"
        onClick={() => setHandleDischarge(true)}
      >
        {t('DISCHARGE')}
      </Button>
      <Button
        color="secondary"
        onClick={() => setHandleCancelAccepted(true)}
      >
        {t('Cancelar')}
      </Button>
    </>
  )

  const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
  // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)

  return (
    _id && (
      <>
        <OfferDialog
          onClose={onClose}
          type={OFFER_TYPES.OFFER}
          bedType={bedType}
          gender={gender}
          covid={covid}
          isolation={isolation}
          title={title}
          subheader={subheader}
          messages={messages}
          content={content}
          actions={actions}
          newEvent={newEvent}
        />
        <Dialog
          open={handleRejectAccepted}
          onClose={() => setHandleRejectAccepted(false)}
        >
          <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('cancel_alert_content')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleRejectAccepted(false)} color="primary">
          No
            </Button>
            <Button onClick={() => rejectedAcceptedAndClose(item, hospital)} color="primary">
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={handleCancelAccepted}
          onClose={() => setHandleCancelAccepted(false)}
        >
          <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('reject_offer_alert_title', { patientId })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleCancelAccepted(false)} color="primary">
          No
            </Button>
            <Button onClick={() => cancelAcceptedAndClose(item, hospital)} color="primary">
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={handleDischarge}
          onClose={() => setHandleDischarge(false)}
        >
          <DialogTitle>{t('discharge_alert_title', { patientId })}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('discharge_text')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleDischarge(false)} color="primary">
        No
            </Button>
            <Button onClick={() => dischargeAndClose(item, hospital)} color="primary" data-cy="acceptDischarge">
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

export default withTranslation()(AcceptedOfferDialog)
