import _ from 'lodash'
import moment from 'moment'

export function omitByRecursively(value, iteratee) {
  const cb = v => omitByRecursively(v, iteratee)
  // eslint-disable-next-line
  return _.isObject(value)
    ? _.isArray(value)
      ? _.map(value, cb)
      : _(value).omitBy(iteratee).mapValues(cb).value()
    : value
}

export function getResource(resource) {
  const re = new RegExp(`${resource}/([\\w\\-]*)`, 'i')
  const match = window.location.pathname.match(re)
  return match && match[1]
}

export function getHospitalNameById(hospital, id) {
  if (id === hospital._id) return hospital.name
  const selectedHospital = (hospital.source && _.find(hospital.source, h => h._id === id)) ||
    (hospital.target && _.find(hospital.target, h => h._id === id))
  return selectedHospital && selectedHospital.name
}

export const offersFilter = (filter) => (offer) => {
  const checkCondition = (condition) => {
    if (condition === 'date' && filter['date']) {
      return offer[condition] && moment(offer[condition]).startOf('d').toJSON() === filter['date'].startOf('d').toJSON()
    }
    return !filter[condition] || filter[condition] === offer[condition] || (
      typeof offer[condition] !== 'string' && (offer[condition]).includes(filter[condition])
    )
  }
  const f = _.every(['gender', 'bedType', 'covid', 'isolation', 'involvedHospitals', 'date'], checkCondition)
  return f
}

export const filterComments = (hospital, comments) => {
  if (!comments) {
    return ''
  }
  return comments
    .filter(comment => [comment.hospitalId, ...(comment.receivers ? comment.receivers : [])].includes(hospital._id))
    .map(c => ({ name: c.hospitalName, text: c.comment, date: c.date }))
}
