import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  }
}))

export default function OfferList ({ targetHospitalName, rejectorName, t }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className={classes.demo}>
            <List>
              { targetHospitalName.map(name => {
                return (
                  <ListItem>
                    {rejectorName.includes(name) || rejectorName === name ? (
                      <>
                        <CancelIcon style={{ fontSize: 15 }} />&nbsp;&nbsp;&nbsp;
                        <ListItemText
                          style={{ color: '000000', fontSize: 10 }}
                          primary={name}
                        />
                        <ListItemText
                          style={{ color: 'red', textAlign: 'right' }}
                          primary={t('rejected')}
                        />
                      </>
                    )
                      : (
                        <>
                          <SendIcon style={{ fontSize: 12 }} />&nbsp;&nbsp;&nbsp;
                          <ListItemText
                            style={{ color: '000000', fontSize: 12 }}
                            primary={<strong>{name}</strong>}
                          />
                          {/* <ListItemText
                            style={{ textAlign: 'right' }}
                            primary={t('sent')}
                          /> */}
                        </>
                      )
                    }
                  </ListItem>
                )
              })}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
