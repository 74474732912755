import React, { useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Badge from '@material-ui/core/Badge'
import ShowMoreIcon from '@material-ui/icons/KeyboardArrowUp'
import ShowLessIcon from '@material-ui/icons/KeyboardArrowDown'
import EditIcon from '@material-ui/icons/Edit'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Card from '@material-ui/core/Card'
// import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
// import AddCommentModal from './AddCommentModal'
import { PALETTE } from '../../constants/palette'
import { BED_TYPES_ACR } from '../../constants/bed-type-acr'

const styles = theme => {
  const unit = theme.spacing(1)
  const { grey } = theme.palette
  const { CARD } = PALETTE
  const { TYPE, GENDER, STATE } = CARD

  return {
    root: {
      marginBottom: 1 * unit,
      border: `1px solid ${grey['400']}`
    },
    raised: {
      border: `1px solid ${grey['400']}`
    },
    actions: {
      padding: 0,
      margin: 0,
      marginLeft: '1px',
      marginRight: '10px',
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap'
      }
    },
    comments: {
      whiteSpace: 'pre-line'
    },
    commentsButton: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: 'auto',
        paddingRight: 0
      }
    },
    addComents: {
      display: 'flex'

    },
    offerType: {
      backgroundColor: TYPE.OFFER
    },
    requestType: {
      backgroundColor: TYPE.REQUEST
    },
    rejectedState: {
      backgroundColor: STATE.REJECTED
    },
    activeState: {
      backgroundColor: STATE.ACTIVE
    },
    acceptedState: {
      backgroundColor: STATE.ACCEPTED
    },
    avatarRoot: {
      fontSize: 15,
      fontWeight: 'bold'
    },
    male_isolatedAvatar: {
      backgroundColor: GENDER.MALE,
      border: `5px solid ${grey['400']}`
    },
    femaleisolatedAvatar: {
      backgroundColor: GENDER.FEMALE,
      border: `5px solid ${grey['400']}`
    },
    indifferentisolatedAvatar: {
      backgroundColor: GENDER.INDIFFERENT,
      border: `5px solid ${grey['400']}`
    },
    male_noIsolatedAvatar: {
      backgroundColor: GENDER.MALE
    },
    femalenoIsolatedAvatar: {
      backgroundColor: GENDER.FEMALE
    },
    indifferentnoIsolatedAvatar: {
      backgroundColor: GENDER.INDIFFERENT
    },
    overTimeNote: {
      backgroundColor: STATE.OVERTIME
    },
    priorityNote: {
      backgroundColor: STATE.PRIORITY
    },
    passiveNote: {
      backgroundColor: STATE.PASSIVE
    }
  }
}

const getNoteClass = (classes, state, overTime, priority, passive) => {
  if(passive) {
    return classes.passiveNote
  }
  if((overTime || priority) && state === 'ACTIVE') {
    if(priority) {
      return classes.priorityNote
    }
    return classes.overTimeNote
  }
  return classes[`${_.toLower(state)}State`]
}

const Note = ({
  classes, t, type, bedType, isOwner, raised, gender, isolation, title, subheader,
  content, messages, actions, state, len, item, notifications, dismissNotifications,
  commentR, hospital, onClickEditButton, priority, passive
}) => {
  const [overTime, setOverTime] = useState(false)
  const [expandComments, setExpandComments] = useState(false)
  const [expandDestination, setExpandDestination] = useState(false)
  const { date: availTime } = item
  const messagesLen = _.size(messages)
  const stateClass = getNoteClass(classes, state, overTime, priority, passive)
  const avatarClass = classes[`${gender}${isolation}Avatar`]
  const commentNotifications = notifications
    ? notifications.filter(noti => (noti.title === 'Nou comentari a la sol·licitud' || noti.title === 'Nou comentari a la oferta') && (noti.offer._id === item._id)) : ''
  const unreadComment = commentNotifications
    ? commentNotifications.filter(newComment => !newComment.read).length : 0

  const seeComments = () => {
    setExpandComments(!expandComments)
    if (state === 'ACTIVE') { dismissNotifications(notifications ? notifications.filter(noti => noti.offer._id === item._id) : '') }
  }
  const hoursToOverTime = 12
  const msToOverTime = hoursToOverTime * 60 * 60 * 1000
  const msDiff = moment().diff(moment(availTime))
  const timeToOverTime = msToOverTime - msDiff
  if (msDiff < msToOverTime) {
    setTimeout(() => { setOverTime(true) }, timeToOverTime)
  } else if (!overTime) {
    setOverTime(true)
  }
  return (
    <Card className={clsx(classes.root, { [classes.raised]: raised })}>
      <CardHeader
        className={stateClass}
        avatar={(
          <Avatar className={clsx(classes.avatarRoot, avatarClass)}>
            {t(BED_TYPES_ACR[bedType])}
          </Avatar>
        )}
        title={title}
        subheader={(overTime && state === 'ACTIVE') ? <span data-cy="overTimeNote" style={{  }}>{subheader}</span> : subheader}
        // subheader={subheader}
        action={((isOwner || item.type === 'REQUEST') && len > 1) && (state === 'ACTIVE' || state === 'REJECTED') ? (
          <IconButton size="small" onClick={() => { setExpandDestination(!expandDestination) }}>
            {expandDestination ? <ShowMoreIcon /> : <ShowLessIcon />}
          </IconButton>
        ) : ''}
      />
      {/* </CardActionArea> */}
      <Collapse in={expandDestination} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography>{content}</Typography>
        </CardContent>
      </Collapse>
      {/* TODO: remove this section when there are no buttons */}
      <CardActions className={classes.actions}>
        {actions}
        {messagesLen > 0 && (
          <Badge badgeContent={unreadComment > 0 ? t('new') : 0} color="secondary">
            <Button data-cy="commentsButton" variant={unreadComment > 0 ? 'contained' : 'text'} onClick={() => seeComments(item)}>
              {t('comments')}
              {/* <MessageIcon /> */}
              {expandComments ? <ShowMoreIcon /> : <ShowLessIcon />}
            </Button>
          </Badge>
        )}
        {isOwner && (
          <div style={{ flexGrow: 1, textAlign: 'right' }}>
            <IconButton size="small" disabled={state !== 'ACTIVE'} onClick={onClickEditButton}>
              <EditIcon />
            </IconButton>
          </div>

        )}

      </CardActions>
      <Collapse in={expandComments} timeout="auto" unmountOnExit>
        <CardContent>
          {messages && messages.map((message, i) => {
            const { name, text, date } = message
            return (
              <div key={i}>
                <Typography title={moment(date).format('HH:mm (DD/MM/YYYY)')} variant="body2" color="textSecondary">
                  {moment(date).fromNow()} <strong>{name}</strong> {t('note.wrote')}:
                  </Typography>
                <Typography data-cy="comments" paragraph={i < messagesLen - 1}>
                  {text}
                </Typography>
              </div>
            )
          })}
          {/* <AddCommentModal
            offer={item}
            commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
          /> */}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default withStyles(styles)(withTranslation()(Note))
