import { REGISTRATION_TOKEN_SYNC } from '../constants/firebase'

export default (state = [], action) => {
  switch (action.type) {
    case REGISTRATION_TOKEN_SYNC:
      return [{
        _id: action.token.slice(0, 32),
        hospitalId: action.hospital._id,
        token: action.token,
        updatedAt: new Date().toJSON()
      }]
    default:
      return state
  }
}
