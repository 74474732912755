import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'
import green from '@material-ui/core/colors/green'
import firebase from 'firebase'
import AlertDialog from '../dialogs/AlertDialog'
import AppBar from '../navigation/AppBar'

const cookies = new Cookies()

const styles = theme => {
  const unit = theme.spacing(1)

  return {
    appBar: {
      backgroundColor: green[700]
    },
    logo: {
      paddingBottom: 2 * unit
    }
  }
}

const Unauthorized = ({ classes, t }) => {
  const handleClose = () => {
    // TODO: Missing or insufficient permissions.
    cookies.remove('login')
    firebase.auth().signOut()
    window.location.href = '/'
  }

  return (
    <>
      <AppBar isSignedIn={false} />
      <AlertDialog
        open
        title={t('unauthorized')}
        message={t('unauthorizedText')}
        onAccept={handleClose}
        onClose={handleClose}
        acceptTitle={t('toolbar.dialog_title')}
      />
    </>
  )
}

export default withStyles(styles)(withTranslation()(Unauthorized))
