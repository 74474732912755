import React from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'

const AcceptedRejectedDialog = ({
  t, classes, hospital, item, onClose, newEvent
}) => {
  const {
    _id, type, bedType, gender, covid, isolation, numBeds, patientId, date, creationDate, acceptDate,
    comments, commentsOffered, commentsRequested, acceptor, involvedHospitals: [hospitalId], log
  } = item
  const originName = _id ? getHospitalNameById(hospital, log ? log[log.length -1].hospital : hospitalId) : ''
  const acceptorName = acceptor && acceptor.name
  let title = null
  let subheader = null
  let content = null

  if (type === 'OFFER') {
    title = (
      <span>{t('acceptedOfferNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
        ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
          ? <strong>{t('isolated')}</strong> : ''}
      </span>
    )
    subheader = t('acceptedOfferNote.subheader', { date })
    content = (
      <>
        <Trans i18nKey="acceptedRejectedOfferNote">
          <strong>{{ originName }}</strong> has REJECTED the reservation of the bed
         for the patient with the ID: <strong>{{ patientId }}</strong>
        </Trans>
        <p style={{ fontStyle: 'italic' }}>{t('acceptedRejectedOfferExtraNote')}</p>
      </>
    )
  } else {
    title = (
      <span>{t('acceptedRequestNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
        ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
          ? <strong>{t('isolated')}</strong> : ''}
      </span>
    )
    subheader = t('acceptedRequestNote.subheader', { date })
    content = (
      <Trans i18nKey="acceptedRejectedRequestNote">
        <strong>{{ originName }}</strong> has REJECTED the acceptance of its request
         for the patient with the ID: <strong>{{ patientId }}</strong>
      </Trans>
    )
  }

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)

  if (item.cancelReason) {
    content = (
      <>
        {content} <br />
        {t('reason')}: {t(item.cancelReason)}
      </>
    )
  }

  return (
    _id && (
      <OfferDialog
        onClose={onClose}
        type={OFFER_TYPES.OFFER}
        bedType={bedType}
        gender={gender}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        newEvent={newEvent}
      />
    )
  )
}

export default withTranslation()(AcceptedRejectedDialog)
