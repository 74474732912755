import { NOTIFICATION_DISMISS, NOTIFICATION_UNDISMISS } from '../constants/notifications'

const dismissNotifications = (state, action) => {
  const ids = action.notifications.map(n => n._id)
  return state.map(o => (ids.indexOf(o._id) > -1 ? { ...o, read: true } : o))
}

const undismissNotifications = (state, action) => {
  const ids = action.notifications.map(n => n._id)
  return state.map(o => (ids.indexOf(o._id) > -1 ? { ...o, read: false } : o))
}

export default (state = [], action) => {
  switch (action.type) {
    case NOTIFICATION_DISMISS:
      return dismissNotifications(state, action)
    case NOTIFICATION_UNDISMISS:
      return undismissNotifications(state, action)
    default:
      return state
  }
}
