import React, { Fragment, Suspense, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import Tour from './components/navigation/Tour'
import HomePage from './components/pages/HomePage'
import NotFoundPage from './components/pages/NotFoundPage'
import OfferPage from './containers/OfferPage'
import NotificationsPage from './containers/NotificationsPage'
import HistoryPage from './components/pages/HistoryPage'
import { AppContext } from './lib/context'
import { theme } from './constants/theme'

const App = ({ history, pageNotFound = false }) => {
  const [filter, setFilter] = useState({
    bedType: '',
    gender: '',
    isolation: '',
    covid: '',
    involvedHospitals: ''
  })
  const [tabIndex, setTabIndex] = useState(0)
  const [isTourOpen, setTourOpen] = useState(false)

  return (

    <AppContext.Provider
      value={{
        filter,
        setFilter,
        tabIndex,
        setTabIndex,
        setTourOpen
      }}
    >
      <Suspense fallback={<Fragment />}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/offers/:id" component={OfferPage} />
              <Route path="/notifications" component={NotificationsPage} />
              <Route path="/history" component={HistoryPage} />
              <Route render={(props) => <NotFoundPage />} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
        <Tour isTourOpen={isTourOpen} setTourOpen={setTourOpen} />
      </Suspense>
    </AppContext.Provider>
  )
}

export default App
