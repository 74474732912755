import { NOTIFICATION_DISMISS, NOTIFICATION_UNDISMISS } from '../constants/notifications'

export const dismissNotifications = notifications => ({
  type: NOTIFICATION_DISMISS,
  notifications
})

export const undismissNotifications = notifications => ({
  type: NOTIFICATION_UNDISMISS,
  notifications
})
