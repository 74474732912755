import React, { useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DeleteIcon from '@material-ui/icons/Delete'
import { addEmail as addEmailAction } from '../../actions/user'
import { deleteDB as deleteDBAction } from '../../actions/shared'

const styles = theme => {
  const unit = theme.spacing(1)
  const { grey } = theme.palette

  return {
    textField: {
      marginBottom: 2 * unit
    },
    addEmail: {
      flexGrow: 1
    },
    list: {
      overflow: 'auto',
      maxHeight: 22 * unit,
      border: `1px solid ${grey[200]}`,
      backgroundColor: grey[50]
    }
  }
}

const Preferences = ({
  classes, i18n, t, hospital, addEmail, open, onClose, deleteDB
}) => {
  const [newEmail, setNewEmail] = useState('')
  const [openDeleteDB, setOpenDeleteDB] = useState(false)

  const handleLanguageChange = e => {
    const language = e.target.value

    window.localStorage.setItem('lng', language)
    moment.locale(language)
    i18n.changeLanguage(language)
  }
  const handleEmailChange = e => setNewEmail((e.target.value).trim())
  const handleAddEmail = () => {
    if (newEmail.length > 0) {
      addEmail(hospital, newEmail)
      setNewEmail('')
    }
  }

  const deleteDisagree = () => {
    setOpenDeleteDB(false)
  }
  const deleteAgree = () => {
    deleteDB()
    // firestore.collection('offers').delete()
    setOpenDeleteDB(false)
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('user_preferences')}</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.textField}
          select
          fullWidth
          label={t('language')}
          value={i18n.language}
          onChange={handleLanguageChange}
        >
          <MenuItem value="ca">Català</MenuItem>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="es">Español</MenuItem>
        </TextField>
        <Grid item container spacing={8} alignItems="flex-end" className={classes.textField}>
          <Grid item className={classes.addEmail}>
            <TextField
              fullWidth
              label={t('add_email')}
              value={newEmail}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              onClick={handleAddEmail}
            >
              <Add />
            </Button>
          </Grid>
        </Grid>
        <List className={classes.list}>
          {!hospital.users ? null : hospital.users.map(email => (
            <ListItem key={email}>
              <ListItemText>
                {email}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions>
        { (window.location.host === 'localhost:3000' || window.location.host === 'demo.mint.amalfianalytics.com') && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            // startIcon={<DeleteIcon/>}
            onClick={() => setOpenDeleteDB(true)}
          >
            {t('Delete Database')}
            <DeleteIcon />
          </Button>
        )}
        <Dialog
          open={openDeleteDB}
          // onClose={() => setOpenDeleteDB(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('Delete question')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('Delete message')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteDisagree} color="primary">
              {t('disagree')}
            </Button>
            <Button onClick={deleteAgree} color="primary" autoFocus>
              {t('agree')}
            </Button>
          </DialogActions>
        </Dialog>

      </DialogActions>

      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  hospital: state.hospitals.length > 0 ? state.hospitals[0] : {}
})

const mapDispatchToProps = dispatch => ({
  addEmail: (hospital, email) => dispatch(addEmailAction(hospital, email)),
  deleteDB: () => dispatch(deleteDBAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(withStyles(styles)(Preferences))
)
