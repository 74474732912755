import firebase from 'firebase'

import { takeLatest, put, takeEvery } from 'redux-saga/effects'

import { SWITCH_HOSPITAL } from '../constants/firebase'
import { setRegistrationToken } from '../actions/firebase'
import { newEvent } from '../actions/shared'

import rsf from '../rsf'

function* newMessage (message) {
  if (message) {
    yield put(newEvent({ message: message.data.title }))
  }
}

function* messagingService (action) {
  const messaging = firebase.messaging()
  try {
    yield messaging.requestPermission()
    const token = yield messaging.getToken()
    yield put(setRegistrationToken(token, action.hospitalId))
    // messaging.onMessage(payload => console.log(payload))
    // yield fork(rsf.messaging.syncMessages, { successActionCreator: newMessage })
  } catch (error) {
    console.warn('Notifications blocked')
    console.log(error)
  }
}

let watchSwitchHospitalForMessagingVar = null
let watchMessagesVar = null

try {
  const messageChannel = rsf.messaging.channel()
  watchSwitchHospitalForMessagingVar = takeLatest(SWITCH_HOSPITAL, messagingService)
  watchMessagesVar = takeEvery(messageChannel, newMessage)
} catch (e) {
  console.warn('Messaging not supported')
  console.warn(e)
}

export const watchSwitchHospitalForMessaging = watchSwitchHospitalForMessagingVar
export const watchMessages = watchMessagesVar
