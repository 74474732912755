import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import UnreadIcon from '@material-ui/icons/Bookmark'
import ReadIcon from '@material-ui/icons/BookmarkBorder'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import authenticated from '../../HOCs/authenticated'
import MainView from '../../containers/MainView'
import { PALETTE } from '../../constants/palette'
import { BED_TYPES_ACR } from '../../constants/bed-type-acr'
// import highlightNotification from '../../actions/selectedNotification'

const styles = theme => {
  const { divider: dividerColor } = theme.palette
  const { grey } = theme.palette
  const { CARD } = PALETTE
  const { GENDER } = CARD

  return {
    dialogContent: {
      backgroundColor: green[50],
      padding: 0,
      borderTop: `1px solid ${dividerColor}`,
      borderBottom: `1px solid ${dividerColor}`,
      minWidth: theme.breakpoints.values.sm,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial'
      }
    },
    unread: {
      fontWeight: 'bold'
    },
    listItemTextClass: {
      marginLeft: '10px'
    },
    avatarRoot: {
      fontSize: 15,
      fontWeight: 'bold'
    },
    male_isolatedAvatar: {
      backgroundColor: GENDER.MALE,
      border: `5px solid ${grey['400']}`
    },
    femaleisolatedAvatar: {
      backgroundColor: GENDER.FEMALE,
      border: `5px solid ${grey['400']}`
    },
    indifferentisolatedAvatar: {
      backgroundColor: GENDER.INDIFFERENT,
      border: `5px solid ${grey['400']}`
    },
    male_noIsolatedAvatar: {
      backgroundColor: GENDER.MALE
    },
    femalenoIsolatedAvatar: {
      backgroundColor: GENDER.FEMALE
    },
    indifferentnoIsolatedAvatar: {
      backgroundColor: GENDER.INDIFFERENT
    }
  }
}

const NotificationsPage = ({
  t, classes, history, notifications, dismissNotifications, undismissNotifications, highlightNotification
}) => {
  const handleClose = () => history.push('/')
  const handleClickItem = item => () => {
    history.push(`/offers/${item.offer._id}`)
    dismissNotifications([item])
    // highlightNotification([item])
  }
  return (
    <>
      <MainView />
      <Dialog open onClose={handleClose}>
        <DialogTitle>Notifications</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <List disablePadding>
            {notifications.map((item, key) => {
              const {
                message, date, offer, read
              } = item
              const { gender, isolation, bedType } = offer
              const tooltipTitle = !read ? t('markAsRead') : t('markAsUnread')
              const avatarClass = classes[`${gender}${isolation}Avatar`]

              return (
                <ListItem key={key} button onClick={handleClickItem(item)}>
                  <Avatar className={clsx(classes.avatarRoot, avatarClass)}>
                    {/* <Avatar className={avatarClass}> */}
                    {/* <BedIcon /> */}
                    {t(BED_TYPES_ACR[bedType])}
                  </Avatar>
                  <ListItemText
                    className={classes.listItemTextClass}
                    primaryTypographyProps={{ className: clsx({ [classes.unread]: !read }) }}
                    primary={t(message)}
                    secondary={moment(date).fromNow()}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={tooltipTitle} placement="bottom-end">
                      <IconButton
                        onClick={() => (item.read
                          ? undismissNotifications([item])
                          : dismissNotifications([item]))
                        }
                      >
                        {read ? <ReadIcon /> : <UnreadIcon />}
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => dismissNotifications(notifications)}>
            {t('markAllAsRead')}
          </Button>
          <Button onClick={handleClose}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default authenticated(
  withStyles(styles)(
    withTranslation()(NotificationsPage)
  )
)
