import React from 'react'
import 'moment/locale/es'
import 'moment/locale/ca'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => {
  const unit = theme.spacing(1)
  const margin = 2 * unit

  return {
    info: {
      padding: `${margin}px ${margin}px`,
      margin: `${unit}px 0 ${margin}px`
    },
    textField: {
      marginBottom: margin
    }
  }
}

class CommentRequestModal extends React.Component {
  state = {
    newComent: ''
  }

  handleChange = element => event => this.setState({ [element]: event.target.value })

  isValidForm = () => {
    const { newComent } = this.state

    return newComent.length > 0
  }

  render () {
    const {
      open, handleClose, classes, title, commentRequest, t
    } = this.props

    const {
      newComent
    } = this.state

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t('write_comment')}
            variant="outlined"
            multiline
            rows="4"
            className={classes.textField}
            value={newComent}
            data-cy="commentsRequested"
            onChange={this.handleChange('newComent')}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="acceptDialogAccept"
            disabled={!this.isValidForm()}
            size="small"
            color="primary"
            onClick={() => {
              commentRequest(newComent)
              this.setState({ newComent: '' })
              handleClose()
            }}
          >
            {t('Acceptar')}
          </Button>
          <Button size="small" color="secondary" onClick={handleClose}>
            {t('Cancelar')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withTranslation()(CommentRequestModal))
