import { all } from 'redux-saga/effects'
import * as _ from 'lodash'
import * as syncSagas from './sync'
import * as firebaseMessaging from './firebaseMessaging'
import * as offers from './offers'
import * as hospitals from './hospitals'
import * as fcmToken from './fcmToken'
import * as shared from './shared'
import * as user from './user'

export default function * rootSaga () {
  yield all([
    ..._.values(syncSagas),
    ..._.values(firebaseMessaging),
    ..._.values(offers),
    ..._.values(hospitals),
    ..._.values(fcmToken),
    ..._.values(user),
    ..._.values(shared)
  ])
}
