import React, { useState } from 'react'
import firebase from 'firebase'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Popper from '@material-ui/core/Popover'
import Cookies from 'universal-cookie'
import IconoBuscarCama from '../../assets/BUSCAR-CAMA.png'
import IconoOfrecerCama from '../../assets/OFRECER-CAMA.png'
import IconoSolicitarCama from '../../assets/SOLICITAR-CAMA.png'
import IconoAceptarCama from '../../assets/ACEPTAR-CAMA.png'
import IconoBuscarCamaAzul from '../../assets/BUSCAR-CAMA-AZUL.png'
import IconoOfrecerCamaAzul from '../../assets/OFRECER-CAMA-AZUL.png'
import IconoSolicitarCamaAzul from '../../assets/SOLICITAR-CAMA-AZUL.png'
import IconoAceptarCamaAzul from '../../assets/ACEPTAR-CAMA-AZUL.png'
import AppBar from './AppBar'
import NavigationTabs from './NavigationTabs'
import ToolbarButtons from './ToolbarButtons'
import Notifications from '../../containers/Notifications'
import ConfirmDialog from '../dialogs/ConfirmDialog'
import Preferences from '../user/Preferences'

const cookies = new Cookies()

const styles = theme => {
  const unit = theme.spacing(1)

  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    },
    body: {
      height: '100vh',
      overflow: 'auto'
    },
    gridContainer: {
      height: `100%`
    },
    gridItem: {
      padding: `${2 * unit}px ${unit}px ${2 * unit}px`,
      height: '100%'
    }
  }
}

const MainLayout = ({
  t, classes, columns, hospital, notifications
}) => {
  const [filterCol, ...cols] = columns
  const [isExitDialogOpen, setExitDialogOpen] = useState(false)
  const [isPreferencesDialogOpen, setPreferencesDialogOpen] = useState(false)
  const [isNotificationListOpen, setNotificationListOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleToggleExitDialog = () => setExitDialogOpen(!isExitDialogOpen)
  const handleTogglePreferencesDialog = () => setPreferencesDialogOpen(!isPreferencesDialogOpen)
  const handleExit = () => {
    cookies.remove('login')
    firebase.auth().signOut()
    window.location.href = '/'
  }

  const handleOpenNotifications = event => {
    setAnchorEl(event.currentTarget)
    setNotificationListOpen(!isNotificationListOpen)
  }

  return (
    <div className={classes.root}>
      <AppBar hospital={hospital} />
      {/* Mobile version */}
      <Hidden mdUp>
        <NavigationTabs
          columns={columns}
          columnIcons={[
            // eslint-disable-next-line max-len
            [IconoBuscarCama, IconoBuscarCamaAzul], [IconoOfrecerCama, IconoOfrecerCamaAzul], [IconoSolicitarCama, IconoSolicitarCamaAzul], [IconoAceptarCama, IconoAceptarCamaAzul]
            // IconoBuscarCama, IconoOfrecerCama, IconoSolicitarCama, IconoAceptarCama
            // FilterListIcon, HotelIcon, ShoppingCartIcon, WorkIcon
          ]}
        />
      </Hidden>
      {/* Desktop version */}
      <Hidden smDown>
        <div className={classes.body}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={2} className={classes.gridItem}>
              {filterCol}
            </Grid>
            <Grid item xs={10} container className={classes.gridContainer}>
              {cols.map((col, i) => (
                <Grid key={i} item xs={4} className={classes.gridItem}>
                  {col}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </Hidden>
      <ToolbarButtons
        notifications={notifications}
        onExit={handleToggleExitDialog}
        onOpenUserPreferences={handleTogglePreferencesDialog}
        onOpenNotifications={handleOpenNotifications}
      />
      <Preferences open={isPreferencesDialogOpen} onClose={() => setPreferencesDialogOpen(false)} />
      <Popper
        open={isNotificationListOpen}
        anchorEl={anchorEl}
        onClose={handleOpenNotifications}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Notifications />
      </Popper>
      <ConfirmDialog
        open={isExitDialogOpen}
        title={t('toolbar.dialog_title')}
        message={t('toolbar.dialog_text')}
        onClose={handleToggleExitDialog}
        onAccept={handleExit}
      />
    </div>
  )
}

export default withStyles(styles)(
  withTranslation()(MainLayout)
)
