import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import green from '@material-ui/core/colors/green'
import UnreadIcon from '@material-ui/icons/Bookmark'
import ReadIcon from '@material-ui/icons/BookmarkBorder'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const styles = theme => {
  const unit = theme.spacing(1)
  const { divider: dividerColor } = theme.palette

  return {
    root: {
      textAlign: 'center'
    },
    gridItem: {
      borderBottom: `1px solid ${dividerColor}`,
      '&:last-child': {
        borderBottom: 'none'
      }
    },
    body: {
      backgroundColor: green[50]
    },
    button: {
      margin: `${unit / 2}px 0`
    },
    unread: {
      fontWeight: 'bold'
    }
  }
}

const Notifications = ({
  t, classes, notifications, history, dismissNotifications, undismissNotifications, highlightNotification
}) => {
  const handleClickItem = item => () => {
    history.push(`/offers/${item.offer._id}`)
    dismissNotifications([item])
  }

  // const handleClickItem = item => () => {
  //   highlightNotification([item])
  //   dismissNotifications([item])
  // }
  return (
    <Paper className={classes.root}>
      <Grid container direction="column">
        <Grid item className={classes.gridItem}>
          <Button className={classes.button} onClick={() => dismissNotifications(notifications)}>
            {t('markAllAsRead')}
          </Button>
        </Grid>
        <Grid item className={clsx(classes.gridItem, classes.body)}>
          <List dense disablePadding>
            {notifications.slice(0, 5).map((item, key) => {
              const {
                message, date, read
              } = item
              const tooltipTitle = !item.read ? t('markAsRead') : t('markAsUnread')

              return (
                <ListItem key={key} button onClick={handleClickItem(item)}>
                  <ListItemText
                    data-cy="notificationItem"
                    primaryTypographyProps={{ className: clsx({ [classes.unread]: !read }) }}
                    primary={t(message)}
                    secondary={moment(date).fromNow()}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={tooltipTitle} placement="bottom-end">
                      <IconButton
                        onClick={() => (item.read
                          ? undismissNotifications([item])
                          : dismissNotifications([item]))
                        }
                      >
                        {item.read ? <ReadIcon /> : <UnreadIcon />}
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Button className={classes.button} onClick={() => history.push('/notifications')}>
            {t('seeAll')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withRouter(withStyles(styles)(withTranslation()(Notifications)))
