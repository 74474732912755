import { connect } from 'react-redux'
import moment from 'moment'
import Snackbar from '../components/shared/Snackbar'

// const mapStateToProps = ({ events }) => {
//   console.log(events)
//   const evts = events.filter(e => moment().diff(moment(e.date), 'seconds') < 2)
//   return { event: evts.length > 0 ? evts[0] : null }
// }
const mapStateToProps = ({ notifications }) => {
  const evts = notifications.filter(e => moment().diff(moment(e.date), 'seconds') < 4)
  return { event: evts.length > 0 ? evts.slice(-1).pop() : null }
}

export default connect(mapStateToProps)(Snackbar)
