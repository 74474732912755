import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MaterialTable, { MTableToolbar } from 'material-table'
import { useCollection } from 'react-firebase-hooks/firestore'
import Collapse from '@material-ui/core/Collapse'
import firebaseApp from '../../firebaseSetup'
import authenticated from '../../HOCs/authenticated'
import AppBar from '../navigation/AppBar'
import IdPortal from '../shared/IdPortal'

const styles = theme => {
  const { divider: dividerColor } = theme.palette

  return {
    dialogContent: {
      backgroundColor: green[50],
      padding: 0,
      borderTop: `1px solid ${dividerColor}`,
      borderBottom: `1px solid ${dividerColor}`,
      minWidth: theme.breakpoints.values.sm,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial'
      }
    }
  }
}
const StyledButton = withStyles({
  root: {
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid',
    color: 'white',
    height: 40,
    padding: '0 25px',
    boxShadow: '0 2px 3px 1px rgba(255, 255, 255, .3)'
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '1rem'
  }
})(Button)

function useOffers (hospital, sortAttr, limit, daysBefore = 7) {
  const hospitalId = hospital ? hospital._id : '-1'

  const daysAgo = moment().subtract(daysBefore, 'd').startOf('d').toJSON()

  const query = firebaseApp.firestore().collection('offers')
    .where('involvedHospitals', 'array-contains', hospitalId)
    .where('date', '>=', daysAgo)
    .orderBy(sortAttr, 'desc')
    .limit(limit)

  const [snapshot, loading, error] = useCollection(query)

  const offers = (hospital && !loading && snapshot !== undefined) ? snapshot.docs.map(d => d.data()) : []
  return [offers, !hospital || loading, error]
}

function targetHospitalsName (hospital, offer, t) {
  return offer.targetHospitalsNames.join(', ')

  // eslint-disable-next-line max-len
  //   const targetHosp = Array.isArray(offer.targetHospitals) ? offer.targetHospitals : [offer.targetHospitals]
  //   const targetHospitalList = _.uniq(targetHosp.map((targetHospi) => {
  //     if (targetHospi === hospital._id) {
  //       return hospital.name
  //     } if (hospital.source) {
  //       const hospi = hospital.source.filter(h => h._id === targetHospi)
  //       return hospi.length === 0 ? t('other') : hospi[0].name
  //     } if (hospital.target) {
  //       const hospi = hospital.target.filter(h => h._id === targetHospi)
  //       return hospi.length === 0 ? t('other') : hospi[0].name
  //     }
  //     return targetHospi
  //   }))
  //   return targetHospitalList.join(', ')
}

function sourceHospitalsName (hospital, offer) {
  if (offer.sourceHospitals === hospital._id) {
    return hospital.name
  } if (hospital.source) {
    const hospi = hospital.source.filter(h => h._id === offer.sourceHospitals)
    return hospi[0].name
  } if (hospital.target) {
    const hospi = hospital.target.filter(h => h._id === offer.sourceHospitals)
    return hospi[0].name
  }
  return offer.sourceHospitals
}

function ExpandHistoryComments ({ offer, t }) {
  const [expandCom, setExpandCom] = useState(false)
  const showlen = 14
  if (!offer) {
    return '-'
  } if (offer.length < showlen) {
    return <div>{offer}</div>
  }
  const firstComment = offer.substring(0, showlen)
  return (
    <>
      { expandCom ? (
        <Collapse in={expandCom} timeout="auto" unmountOnExit>
          <div>{offer}</div>
        </Collapse>
      ) : (<div>{firstComment}</div>)}
      {/* eslint-disable-next-line */}
      <a onClick={() => { setExpandCom(!expandCom) }} href="#">
        {expandCom ? t('Show less') : <div> {t('Read more')} </div>}
      </a>
    </>
  )
}

function ExtractRequestComments ({ messages, t }) {
  const messagesLen = _.size(messages)
  const [expandCom, setExpandCom] = useState(false)
  return (
    <>
      { expandCom ? (
        <Collapse in={expandCom} timeout="auto" unmountOnExit>
          <div>
            {messages && messages.map((message, i) => {
              const { comment, date, hospitalName } = message
              return (
                <div key={i}>
                  <Typography title={moment(date).format('HH:mm (DD/MM/YYYY)')} variant="body2" color="textSecondary">{moment(date).fromNow()} <strong>{hospitalName}</strong> {t('note.wrote')}:</Typography>
                  <Typography data-cy="comments" paragraph={i < messagesLen - 1}>{comment}</Typography>
                </div>
              )
            })}
          </div>

        </Collapse>
      ) : (<div>{t('comments')}</div>)}
      {/* eslint-disable-next-line */}
      <a onClick={() => { setExpandCom(!expandCom) }} href="#">
        {expandCom ? t('Show less') : <div> {t('Read more')} </div>}
      </a>
      {/* eslint-enable */}
    </>
  )
}

function patientSourceHospitalsName (hospital, offer, t) {
  if ((offer.acceptor._id === hospital._id || offer.sourceHospitals === hospital._id) && offer.type === 'OFFER') {
    return offer.acceptor.name
  } if (offer.type === 'REQUEST') {
    return sourceHospitalsName(hospital, offer, t)
  }
  return t('other')
}
function patientTargetHospitalsName (hospital, offer, t) {
  if ((offer.acceptor._id === hospital._id || offer.sourceHospitals === hospital._id) && offer.type === 'REQUEST') {
    return offer.acceptor.name
  } if (offer.type === 'OFFER') {
    return sourceHospitalsName(hospital, offer, t)
  }
  return t('other')
}

const HistoryPage = ({
  t, classes, history, hospital
}) => {
  const handleClose = () => history.push('/')
  const sortAttr = 'date'
  const limit = 5000
  const [offers, loading, error] = useOffers(hospital, sortAttr, limit, 365)
  const columns = [

    { title: t('available_date'), field: 'date' },
    { title: t('offer_type'), field: 'offerType' },
    { title: t('state'), field: 'offerState' },
    { title: t('Nombre de llits'), field: 'numBeds' },
    { title: t('bed_type'), field: 'bedType' },
    { title: t('Genere'), field: 'gender' },
    { title: t('covidStatus'), field: 'covid' },
    { title: t('isolation'), field: 'isolation' },
    { title: t('Vaccinated'), field: 'vaccinated' },
    { title: t('Id pacient'), field: 'patientId' },
    { title: t('OfferCreator'), field: 'offerRequestCreator' },
    { title: t('receiving_hospital'), field: 'receptorHospital' },
    { title: t('comments_creator'), field: 'comments' },
    { title: t('comments_requested'), field: 'commentsRequested' },
    { title: t('comments_offered'), field: 'commentsOffered' },
    { title: t('Source Hospital'), field: 'sourceHospital' },
    { title: t('Target Hospital'), field: 'targetHospital' },
    { title: t('creation_date'), field: 'creationDate' },
    { title: t('accept_date'), field: 'acceptDate' },
    { title: t('discharged_date'), field: 'dischargedDate' },
    { title: t('arrival_time'), field: 'arrivalTime' },
    { title: t('completed_date'), field: 'completedDate' },
    { title: t('cancel_reason'), field: 'cancelReason' }
  ]

  function commentsToCsv(comments) {
    const csved =  comments.map(comment => {
      const date = moment(comment.date).toJSON()
      return `${date}|${comment.hospitalName}|${comment.comment}`
    }).join('/')
    return csved
  }

  function offerToCSV(offer) {
    const offerObject = {
      date: moment(offer.date).format('YYYY-MM-DD HH:mm:ss') ,
      numBeds: t(offer.numBeds),
      bedType: t(offer.bedType),
      gender: t(offer.gender),
      covid: t(offer.covid),
      isolation: t(offer.isolation),
      vaccinated: t(offer.vaccinated ? 'yes' : 'No'),
      patientId: offer.patientId ? offer.patientId : '-',
      offerRequestCreator: sourceHospitalsName ? sourceHospitalsName(hospital, offer, t) : '-',
      receptorHospital: offer.targetHospitalsNames ? targetHospitalsName(hospital, offer, t) : '-',
      sourceHospital: offer.acceptor ? patientSourceHospitalsName(hospital, offer, t) : '-',
      targetHospital: offer.acceptor ? patientTargetHospitalsName(hospital, offer, t) : '-',
      comments: offer.comments,
      commentsRequested: offer.commentsRequested ? commentsToCsv(offer.commentsRequested) : '-',
      commentsOffered: offer.commentsOffered,
      acceptDate: offer.acceptDate ? moment(offer.acceptDate).format('YYYY-MM-DD HH:mm:ss') : '-',
      creationDate: moment(offer.creationDate).format('YYYY-MM-DD HH:mm:ss') ,
      dischargedDate: offer.dischargedDate ? moment(offer.dischargedDate).format('YYYY-MM-DD HH:mm:ss') : '-',
      arrivalTime: offer.arrivalTime ? moment(offer.arrivalTime).format('YYYY-MM-DD HH:mm:ss') : '-',
      completedDate: offer.completedDate ? moment(offer.completedDate).format('YYYY-MM-DD HH:mm:ss') : '-',
      offerType: t(offer.type),
      offerState: t(offer.state),
      cancelReason: t(offer.cancelReason)
    }
    const csvOffer = columns.map(c => `"${_.get(offerObject, c.field, '-')}"`).join(';')
    return csvOffer
  }

  const downloadCSV = (offers) => () => {
    const header = columns.map(c => c.title).join(';')
    const csvOffers = offers.map(offer => offerToCSV(offer)).join('\n')
    // https://stackoverflow.com/a/18925211
    const blob = new Blob(['\ufeff', header + '\n' + csvOffers], { type: 'text/plain;charset=iso-8859-1;' })
    saveAs(blob, 'mint-history.csv')
  }

  if (loading) {
    const hospi = { name: t('loading...') }
    return (
      <AppBar hospital={hospi} />
    )
  }
  if (error) {
    console.error('Error', error)
    return null
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar hospital={hospital} />
        <IdPortal portalId="portal-tools">
          <StyledButton onClick={handleClose}>
            {t('close')}
          </StyledButton>
        </IdPortal>
      </div>
      <MaterialTable
        title={t('history')}
        columns={columns}
        options={{
          headerStyle: {
            fontWeight: 'bold'
          }
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <div style={{ margin: 10 }}>
                {/* The export options of material-table are not flexible enough. */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={downloadCSV(offers)}
                >
                  {t('download')}
                </Button>
              </div>
              <div style={{ flexGrow: 1 }}>
                <MTableToolbar {...props} />
              </div>
            </div>
          )
        }}
        data={offers.map((offer) => (

          {
            date: moment(offer.date).format('HH:mm (DD/MM/YYYY)'),
            numBeds: t(offer.numBeds),
            bedType: t(offer.bedType),
            gender: t(offer.gender),
            covid: t(offer.covid),
            isolation: t(offer.isolation),
            vaccinated: t(offer.vaccinated ? 'yes' : 'No'),
            patientId: offer.patientId ? offer.patientId : '-',
            offerRequestCreator: sourceHospitalsName ? sourceHospitalsName(hospital, offer, t) : '-',
            receptorHospital: <ExpandHistoryComments offer={offer.targetHospitalsNames ? targetHospitalsName(hospital, offer, t) : '-'} t={t} />,
            sourceHospital: offer.acceptor ? patientSourceHospitalsName(hospital, offer, t) : '-',
            targetHospital: offer.acceptor ? patientTargetHospitalsName(hospital, offer, t) : '-',
            comments: <ExpandHistoryComments offer={offer.comments} t={t} />,
            commentsRequested: offer.commentsRequested ? <ExtractRequestComments messages={offer.commentsRequested} t={t} /> : '-',
            commentsOffered: <ExpandHistoryComments comments={offer.commentsOffered} t={t} />,
            creationDate: moment(offer.creationDate).format('HH:mm (DD/MM/YYYY)'),
            acceptDate: offer.acceptDate ? moment(offer.acceptDate).format('HH:mm (DD/MM/YYYY)') : '-',
            dischargedDate: offer.dischargedDate ? moment(offer.dischargedDate).format('HH:mm (DD/MM/YYYY)') : '-',
            arrivalTime: offer.arrivalTime ? moment(offer.arrivalTime).format('HH:mm (DD/MM/YYYY)') : '-',
            completedDate: offer.completedDate ? moment(offer.completedDate).format('HH:mm (DD/MM/YYYY)') : '-',
            offerType: t(offer.type),
            offerState: t(offer.state),
            cancelReason: t(offer.cancelReason)
          }
        ))}
      />
    </>
  )
}

const mapStateToProps = state => ({
  hospital: state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : null
})

const HistoryPageConnected = connect(mapStateToProps)(HistoryPage)

export default authenticated(
  withStyles(styles)(
    withTranslation()(HistoryPageConnected)
  )
)
