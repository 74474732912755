import {
  CREATE_OFFER, ACCEPT_OFFER, CANCEL_OFFER,
  SET_FILTER, COMMENT_REQUEST, CONFIRM_OFFER, CANCEL_ACCEPTED, REJECT_REQUEST, REJECT_ACCEPTED, COMPLETE_OFFER, DISCHARGE_OFFER, DELETE_OFFER, EDIT_OFFER
} from '../constants/offers'
import { QUERY_SYNC } from '../constants/redux-firestore-sync'

export const createOffer = (offer, hospital) => ({
  type: CREATE_OFFER,
  offer,
  hospital
})

export const editOffer = (offer, hospital) => ({
  type: EDIT_OFFER,
  offer,
  hospital
})

export const acceptOffer = (offer, hospital) => ({
  type: ACCEPT_OFFER,
  offer,
  hospital
})

export const cancelOffer = (offer, hospital, reason) => ({
  type: CANCEL_OFFER,
  offer,
  hospital,
  reason
})

export const cancelAccepted = (offer, hospital, reason) => ({
  type: CANCEL_ACCEPTED,
  offer,
  hospital,
  reason
})
export const rejectAccepted = (offer, hospital, reason) => ({
  type: REJECT_ACCEPTED,
  offer,
  hospital,
  reason
})

export const rejectRequest = (offer, hospital) => ({
  type: REJECT_REQUEST,
  offer,
  hospital
})

export const confirmOffer = (offer, hospital) => ({
  type: CONFIRM_OFFER,
  offer,
  hospital
})

export const completeOffer = (offer, hospital) => ({
  type: COMPLETE_OFFER,
  offer,
  hospital
})

export const dischargeOffer = (offer, hospital) => ({
  type: DISCHARGE_OFFER,
  offer,
  hospital
})
export const deleteRejected = (offer, hospital) => ({
  type: DELETE_OFFER,
  offer,
  hospital
})

export const setFilter = (filter) => ({
  type: SET_FILTER,
  filter
})

export const commentRequest = (newComment, offerId, hospital, date) => ({
  type: COMMENT_REQUEST,
  newComment,
  offerId,
  hospital,
  date
})

export const loadOffer = offerId => ({
  type: QUERY_SYNC,
  queries: {
    extraOffer: [{
      collection: 'offers',
      where: [['_id', '==', ':offerId']]
    }]
  },
  params: {
    offerId
  }
})
