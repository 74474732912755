import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import AppBar from '../navigation/AppBar'

const styles = theme => {
  const unit = theme.spacing(1)

  return {
    appBar: {
      backgroundColor: green[700]
    },
    logo: {
      paddingBottom: 2 * unit
    }
  }
}

const Loading = ({ classes }) => <AppBar isSignedIn={false} isLoading />

export default withStyles(styles)(Loading)
