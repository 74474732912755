import {
  takeLatest, put, take, select
} from 'redux-saga/effects'
import { QUERY_SYNC, SYNC_COMPLETE } from '../constants/redux-firestore-sync'
import { USER_LOGIN_SUCCESS } from '../constants/user'
import { SWITCH_HOSPITAL } from '../constants/firebase'
import { unauthorizedUser } from '../actions/user'

const hospitalCollections = {
  hospitals: [{
    collection: 'hospitals',
    where: ['users', 'array-contains', ':user.email']
  }]
}

let lastEmail = null

function * syncHospital (action) {
  if (lastEmail === action.user.email) {
    return
  }
  lastEmail = action.user.email
  yield put({ type: QUERY_SYNC, queries: hospitalCollections, params: action })
  yield take(a => (a.type === SYNC_COMPLETE && a.reducers.includes('hospitals')))
  const hospitals = yield select(s => s.hospitals)
  if (hospitals.length === 0) {
    yield put(unauthorizedUser())
  } else {
    yield put({ type: SWITCH_HOSPITAL, hospitalId: hospitals[0]._id })
  }
}

export const watchLoginSuccess = takeLatest(USER_LOGIN_SUCCESS, syncHospital)
