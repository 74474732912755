import * as _ from 'lodash'
import firebase from 'firebase'
import { combineReducers } from 'redux'
import {
  takeEvery, put, fork, cancel, all, take
} from 'redux-saga/effects'
import {
  QUERY_SYNC, UPDATE_COLLECTION, SYNC_START, SYNC_COMPLETE, FIRST_UPDATE_COLLECTION
} from '../constants/redux-firestore-sync'
import store from '../store'
import reducers from '../reducers'
import rsf from '../rsf'
import { omitByRecursively } from '../lib/utils'

let currentReducers = reducers

const getRequiredUpdates = (queries) => _.reduce(queries, (prev, cur) => prev + cur.length, 0)

// Waits until all the queries have been synced and updates the store reducers
function * syncWait (queries, newReducers, oldReducers) {
  const requiredQueries = getRequiredUpdates(queries)
  const tempReducers = {}
  _.each(newReducers, (v, k) => {
    tempReducers['__' + k] = v
  })
  store.replaceReducer(combineReducers({ ...oldReducers, ...tempReducers }))
  const delayedUpdates = []
  for (let i = 0; i < requiredQueries; i += 1) {
    delayedUpdates.push(yield take(a => {
      return a.type === FIRST_UPDATE_COLLECTION && _.keys(queries).includes(a.reducerName)
    }))
  }
  store.replaceReducer((state, action) => {
    const tempStatesList = _.filter(_.map(state, (v, k) => [k, v]), ([k, v]) => {
      return k.startsWith('__') && _.keys(queries).includes(k.slice(2))
    })
    const tempStates = {}
    tempStatesList.forEach(([k, v]) => {
      tempStates[k.slice(2)] = v
    })
    return { ...state, ...tempStates }
  })
  store.replaceReducer(combineReducers({ ...oldReducers, ...newReducers }))
  yield all(delayedUpdates.map(u => put({ ...u, type: UPDATE_COLLECTION })))
  yield put({ type: SYNC_COMPLETE, reducers: _.keys(newReducers) })
}

// eslint-disable-next-line
function syncReducers (reducers, queries) {
  const fbReducers = _.mapValues(queries, (q, k) => firebaseReducer(k, reducers[k], q))
  const reducerTasks = _.mapValues(fbReducers, (fbr, k) => fbr.task)
  const nRed = _.mapValues(fbReducers, fbr => fbr.reducer)
  const newReducers = { ...reducers, ...nRed }
  const tasks = { ...reducerTasks, __syncWait: syncWait(queries, nRed, reducers) }
  return { tasks: all(tasks), newReducers }
}

// emits an action of type UPDATE_COLLECTION  when the query gets new data
function * syncCollection (reducerName, query) {
  yield put({ type: SYNC_START, reducerName, query })
  const fsQueries = _.map(query, q1 => {
    const col = firebase.app().firestore().collection(q1.collection)
    return _.reduce(_.omit(q1, 'collection'), (q, params, fn) => {
      if (_.isArray(params) && params.length > 0 && _.isArray(params[0])) {
        return params.reduce((r, c) => r[fn](...c), q)
      }
      if (!_.isArray(params)) {
        return q[fn](params)
      }
      return q[fn](...params)
    }, col)
  })
  const tasks = _.map(fsQueries, subquery => {
    let first = true
    return fork(rsf.firestore.syncCollection,
      subquery, {
        successActionCreator: (data) => {
          const obj = {
            type: first ? FIRST_UPDATE_COLLECTION : UPDATE_COLLECTION,
            reducerName,
            query,
            subquery,
            data
          }
          first = false
          return obj
        }
      })
  })
  const collectionTasks = yield all(tasks)
  yield take(action => (action.type === SYNC_START && action.reducerName === reducerName))
  yield all(collectionTasks.map(t => cancel(t)))
}

// higher order reducer that gets the updates from firestores and updates the reducer and viceversa
function firebaseReducer (reducerName, reducer, query) {
  if (query.length === 0) {
    throw new Error(`Error in query for reducer ${reducerName} Queries should have at least one element`)
  }
  const { collection } = query[0]
  let snapshot = []
  const newReducer = (state = [], action) => {
    if (action.type === UPDATE_COLLECTION && action.reducerName === reducerName) {
      const changes = action.data.docChanges()
      changes.forEach(change => {
        // filterout local changes
        if (change.type === 'added') {
          if (change.doc.metadata.hasPendingWrites &&
             _.find(state, e => e._id === change.doc.id)) {
            return
          }
          const obj = { _id: change.doc.id, ...change.doc.data() }

          state = [...state, obj]
          snapshot = [...snapshot, { id: change.doc.id, obj }]
        } else if (change.type === 'modified' && !change.doc.metadata.hasPendingWrites) {
          const obj = { _id: change.doc.id, ...change.doc.data() }
          state = state.map(e => (e._id === change.doc.id ? obj : e))
          snapshot = snapshot.map(e => (e.id === change.doc.id ? { id: change.doc.id, obj } : e))
        } else if (change.type === 'removed' && !change.doc.metadata.hasPendingWrites) {
          state = state.filter(e => change.doc.id !== e._id)
          snapshot = snapshot.filter(e => change.doc.id !== e.id)
        }
      })
    }

    if (action.type === SYNC_START && action.reducerName === reducerName) {
      state = []
    }
    const newState = reducer(state, action)

    // firestore does not accept undefined field values
    const noUndef = _.curryRight(omitByRecursively)(f => f === undefined)
    // sending local changes to the cloud
    if (newState !== state) {
      const idGroups = _.groupBy([
        ...newState.map(obj => ({ obj, type: 'new' })),
        ...state.map(obj => ({ obj, type: 'old' }))
      ], e => e.obj._id)
      // detecting changes: new elements, deleted elements and edits
      const changes = _.pickBy(idGroups, (v, k) => !k || v.length === 1 || v[0].obj !== v[1].obj)
      const colRef = firebase.app().firestore().collection(collection)
      Promise.all(_.map(changes, (v, k) => {
        if (!k) return Promise.all(v.map(e => colRef.add(noUndef(e.obj))))
        if (v.length === 1) {
          if (v[0].type === 'old') return colRef.doc(k).delete()
          if (k !== 'undefined') return colRef.doc(k).set(noUndef(v[0].obj))
          return colRef.add(noUndef(v[0].obj))
        }
        return colRef.doc(k).set(noUndef(v[0].obj))
      }))
        .catch(e => {
          console.error('Error updating remote DB', e)
        })
    }
    return newState
  }
  return { task: syncCollection(reducerName, query), reducer: newReducer }
}

// helper functions to transform variables with parameter values
const replaceVar = params => f => {
  const match = typeof f === 'string' ? f.match(/^(:[\w.]*)\/?/) : false
  return match
    ? replaceVar(params)(
      f.slice(0, match.index) +
      _.get(params, match[1].slice(1)) +
      f.slice(match.index + match[1].length)
    )
    : f
}
const parseField = params => f => (_.isArray(f) ? f.map(parseField(params)) : replaceVar(params)(f))
const setupQuery = params => query => query.map(q => _.mapValues(q, parseField(params)))

// sync a new set of queries and reducers
function * querySync ({ queries, params }) {
  const sq = setupQuery(params)
  const setupQueries = _.mapValues(queries, sq)
  const oldReducers = { ...currentReducers, ..._.pick(reducers, _.keys(queries)) }
  const { tasks, newReducers } = syncReducers(oldReducers, setupQueries, store)
  currentReducers = newReducers
  yield tasks
}

export const watchQuerySync = takeEvery(QUERY_SYNC, querySync)
