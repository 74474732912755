import React, { useState } from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'
import CancelReason from './CancelReason'


const AcceptedRequestDialog = ({
  t, classes, hospital, discharge, cancelA, rejectA, item, onClose, newEvent
}) => {
  const {
    _id, bedType, gender, covid, isolation, patientId, numBeds, date, creationDate, acceptDate,
    comments, commentsRequested, commentsOffered, acceptor, arrivalTime,
    vaccinated, involvedHospitals: [hospitalId]
  } = item
  const [handleRejectAccepted, setHandleRejectAccepted] = useState(false)
  const [handleCancelAccepted, setHandleCancelAccepted] = useState(false)
  const [cancelReason, setCancelReason] = useState('')

  const [handleDischarge, setHandleDischarge] = useState(false)
  const originName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  const acceptorName = acceptor.name
  const arrivalT = moment(arrivalTime).format('HH:mm (DD/MM/YYYY)')
  // const title = t('acceptedRequestNote.title', { count: numBeds, bedType, gender })
  const title = (
    <span>{t('acceptedRequestNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
        ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  const subheader = t('acceptedRequestNote.subheader', { date })
  const isOwner = hospital._id === hospitalId

  const dischargeAndClose = () => {
    discharge(item, hospital)
    onClose()
  }
  const rejectedAcceptedAndClose = () => {
    rejectA(item, hospital, cancelReason)
    onClose()
  }
  const cancelAcceptedAndClose = () => {
    cancelA(item, hospital, cancelReason)
    onClose()
  }

  const content = (
    <>
      <Trans i18nKey="acceptedRequestNote.text">
        <strong>{{ acceptorName }}</strong> accepted your request.&nbsp;
        {/* <strong>{{ originName }}&apos;s</strong> request. */}
      </Trans>
      {patientId && (
        <>
          <div>
            <Trans i18nKey="note.patientId">
              Patient ID: <strong> {{ patientId }}</strong>
            </Trans>
            <div>
              {vaccinated && t('Vaccinated')}
            </div>
          </div>
          <div>{t('Available at')}&nbsp;{arrivalT}</div>
        </>
      )}
    </>
  )

  const actions = isOwner ? (
    <>
      <Button
        data-cy="acceptedRequestDischargeButton"
        color="primary"
        onClick={() => setHandleDischarge(true)}
      >
        {t('DISCHARGE')}
      </Button>
      <Button
        color="secondary"
        onClick={() => setHandleRejectAccepted(true)}
      >
        {t('cancel')}
      </Button>
    </>
  ) : (''
      // <Button
      //   color="secondary"
      //   onClick={() => setHandleCancelAccepted(true)}
      // >
      //   {t('Cancelar')}
      // </Button>
    )

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)

  return (
    _id && (
      <>
        <OfferDialog
          onClose={onClose}
          type={OFFER_TYPES.REQUEST}
          gender={gender}
          bedType={bedType}
          covid={covid}
          isolation={isolation}
          title={title}
          subheader={subheader}
          messages={messages}
          content={content}
          actions={actions}
          newEvent={newEvent}
        />
        <Dialog
          open={handleRejectAccepted}
          onClose={() => setHandleRejectAccepted(false)}
        >
          <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
          <DialogContent>
            <CancelReason type={OFFER_TYPES.REQUEST} value={cancelReason} onChange={setCancelReason} />
            <DialogContentText>
              {t('cancel_request_alert_content')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleRejectAccepted(false)} color="primary">
              No
          </Button>
            <Button onClick={() => rejectedAcceptedAndClose()} color="primary" disabled={cancelReason.length === 0}>
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={handleDischarge}
          onClose={() => setHandleDischarge(false)}
        >
          <DialogTitle>{t('discharge_alert_title', { patientId })}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('discharge_text')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleDischarge(false)} color="primary">
              No
          </Button>
            <Button onClick={() => dischargeAndClose(item, hospital)} color="primary" data-cy="acceptDischarge">
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={handleCancelAccepted}
          onClose={() => setHandleCancelAccepted(false)}
        >
          <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
          <DialogContent>
            <CancelReason type={OFFER_TYPES.OFFER} value={cancelReason} onChange={setCancelReason} />
            <DialogContentText>
              {t('reject_offer_alert_title', { patientId })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleCancelAccepted(false)} color="primary">
              No
          </Button>
            <Button onClick={() => cancelAcceptedAndClose()} disabled={cancelReason.length === 0} color="primary">
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

export default withTranslation()(AcceptedRequestDialog)
