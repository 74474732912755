import React, { useState } from 'react'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import green from '@material-ui/core/colors/green'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { OFFER_TYPES } from '../../constants/offers'

const styles = theme => {
  const unit = theme.spacing(1)

  return {
    paper: {
      position: 'relative',
      height: '100%',
      backgroundColor: green[50]
    },
    paper2: {
      position: 'relative',
      height: '100%',
      backgroundColor: green[100]
    },
    title: {
      padding: unit,
      color: '#2f3f2e'
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    body: {
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: `${unit}px ${unit}px 0`
    },
    fab: {
      position: 'absolute',
      margin: 2 * unit,
      bottom: 0,
      right: 0
    }
  }
}

const Column = ({
  classes,
  children,
  title,
  FabModal,
  createOfferHandler,
  NoDataComponent,
  AddComponent,
  showFabButton,
  activeTimeInterval,
  tourId,
  acceptedColumn,
  t
}) => {
  const [open, setOpen] = useState(false)
  const [offerType, setOfferType] = useState(OFFER_TYPES.OFFER)
  const [resetActive, setResetActive] = useState(false)
  // eslint-disable-next-line max-len
  const intervalExist = !(activeTimeInterval === undefined || activeTimeInterval == null || activeTimeInterval.length <= 0)
  const activeIntervalHAI = (intervalExist ? (activeTimeInterval.map(interval => moment().format('HH:mm') >= interval.start && moment().format('HH:mm') < interval.end)) : ([true]))
  const activeHAI = activeIntervalHAI.includes(true)
  if (intervalExist) {
    if (!activeHAI) {
      const intervalsStarts = activeTimeInterval.map(interval => moment(interval.start, 'HH:mm').format('HH:mm'))
      const nextStart = intervalsStarts.find(start => start > moment().format('HH:mm'))
      if (intervalsStarts && nextStart === undefined) {
        const firstStart = activeTimeInterval ? (moment(activeTimeInterval[0].start, 'HH:mm').add(1, 'd')) : ''
        const now = moment().format('HH:mm')
        const timeToCheck = moment(firstStart, 'HH:mm').diff(moment(now, 'HH:mm'))
        setTimeout(() => {
          resetActive ? setResetActive(false) : setResetActive(true)
        }, timeToCheck)
      } else {
        const now = moment().format('HH:mm')
        const timeToCheck = moment(nextStart, 'HH:mm').diff(moment(now, 'HH:mm'))
        setTimeout(() => {
          resetActive ? setResetActive(false) : setResetActive(true)
        }, timeToCheck)
      }
    } else if (activeHAI) {
      const intervalsEnds = activeTimeInterval.map(interval => moment(interval.end, 'HH:mm').format('HH:mm'))
      const now = moment().format('HH:mm')
      const nextEnd = intervalsEnds.find(end => end > moment().format('HH:mm'))
      const timeToCheck = moment(nextEnd, 'HH:mm').diff(moment(now, 'HH:mm'))
      setTimeout(() => { resetActive ? setResetActive(false) : setResetActive(true) }, timeToCheck)
    }
  }
  const fbM = FabModal && [
    (
      showFabButton && (
        <Fab
          disabled={!activeHAI}
          key="fab"
          title={t('new_offer')}
          color="primary"
          aria-label="Add"
          data-cy="newOffer"
          className={classes.fab}
          onClick={() => {
            setOfferType(OFFER_TYPES.OFFER)
            setOpen(true)
          }}
        >
          <AddIcon />
        </Fab>
      )
    ),
    (
      <FabModal
        key="fabModal"
        title={offerType === OFFER_TYPES.OFFER ? t('new_offer') : t('new_request')}
        open={open}
        offerType={offerType}
        handleClose={() => setOpen(false)}
        createOfferHandler={(offer) => {
          createOfferHandler({ type: offerType, ...offer })
          setOpen(false)
        }}
      />
    )
  ]

  return (
    <Paper className={acceptedColumn ? classes.paper2 : classes.paper} data-tour={tourId}>
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5" noWrap>
          {title}
        </Typography>
        {AddComponent && (
            <AddComponent
              noData={(_.size(children) === 0)}
              clickHandler={() => {
                setOfferType(OFFER_TYPES.REQUEST)
                setOpen(true)
              }}
            />
          )}
        <div className={classes.body}>

          {(_.size(children) === 0) && NoDataComponent && (
            <NoDataComponent
              clickHandler={() => {
                setOfferType(OFFER_TYPES.REQUEST)
                setOpen(true)
              }}
            />
          )}
          {fbM}
          {children}
        </div>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(withTranslation()(Column))
