import { ADD_EMAIL } from '../constants/user'

export default (state = [], action) => {
  switch (action.type) {
    case ADD_EMAIL:
      return state.map(h => (h._id === action.hospital._id
        ? { ...h, users: [...h.users, action.email] }
        : h))
    default:
      return state
  }
}
