import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const styles = (muiBaseTheme) => ({
  root: {
    margin: 10,
    paddingBottom: 10,
  },
  actions: {
    justifyContent: 'center'
  }
})

const CreateRequestButton = ({
  classes, t, clickHandler, noData
}) => {
  return (
    <Card raised className={classes.root}>
      <CardActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={clickHandler} data-cy="createRequest">
          {t('create_request')}
        </Button>
      </CardActions>
      {noData && (
        <CardContent>
          <Typography align="center" variant="overline">
            {t('no_available_offers')}
          </Typography>
        </CardContent>
      )}
    </Card>
  )
}

export default withTranslation()(
  withStyles(styles)(CreateRequestButton)
)
