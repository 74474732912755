import {
  USER_LOGIN_SUCCESS, ADD_EMAIL, UNAUTHORIZED_USER, START_REFRESH_TOKEN_LIFECYCLE
} from '../constants/user'

export const userLoginSuccess = (user, location) => ({
  type: USER_LOGIN_SUCCESS,
  user,
  location
})

export const addEmail = (hospital, email) => ({
  type: ADD_EMAIL,
  hospital,
  email
})

export const unauthorizedUser = () => ({
  type: UNAUTHORIZED_USER
})

export const startRefreshTokenLifecycle = () => ({
  type: START_REFRESH_TOKEN_LIFECYCLE
})
