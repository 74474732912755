import { takeLatest, call } from 'redux-saga/effects'
import { DELETE_DB } from '../constants/shared'

const deleteDBApi = async () => {
  if (window.location.host.startsWith('localhost') && process.env.REACT_APP_EMULATOR === 'true') {
    await fetch(
      'http://localhost:5001/mint-dev-532af/us-central1/app/offers',
      { method: 'DELETE' }
    )
  } else {
    await fetch(
      'https://us-central1-mint-dev-532af.cloudfunctions.net/app/offers',
      { method: 'DELETE' }
    )
  }
}

function * deleteDBSaga () {
  yield call(deleteDBApi)
}

export const watchDeleteDB = takeLatest(DELETE_DB, deleteDBSaga)
