import React, { useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { acceptOffer, cancelOffer, commentRequest } from '../../actions/offers'
import Note from './Note'
import AcceptOfferModal from './AcceptOfferModal'
import CommentRequestModal from './CommentRequestModal'
import { OFFER_TYPES } from '../../constants/offers'
import CancelReason from '../dialogs/CancelReason'

const OfferNote = ({
  t, hospital, accept, cancel, commentR, item, activeTimeInterval, notifications, dismissNotifications, onEditOffer
}) => {
  const [open, setOpen] = useState(false)
  const [openOfferComment, setOpenOfferComment] = useState(false)
  const [handleCancel, setHandleCancel] = useState(false)
  const [resetActive, setResetActive] = useState(false)
  const [cancelReason, setCancelReason] = useState('')

  // TODO: verify that involvedHospitals length is always greater than zero
  const {
    bedType, state, gender, covid, isolation, numBeds, date: availTime, creationDate, comments,
    commentsRequested, targetHospitals, involvedHospitals: [hospitalId]
  } = item
  // eslint-disable-next-line max-len
  const intervalExist = !(activeTimeInterval === undefined || activeTimeInterval == null || activeTimeInterval.length <= 0)
  const activeIntervalHAI = (intervalExist ? (activeTimeInterval.map(interval => moment().format('HH:mm') >= interval.start && moment().format('HH:mm') < interval.end)) : ([true]))
  const activeHAI = activeIntervalHAI.includes(true)
  if (intervalExist) {
    if (!activeHAI) {
      const intervalsStarts = activeTimeInterval.map(interval => moment(interval.start, 'HH:mm').format('HH:mm'))
      const nextStart = intervalsStarts.find(start => start > moment().format('HH:mm'))
      if (intervalsStarts && nextStart === undefined) {
        const firstStart = activeTimeInterval ? (moment(activeTimeInterval[0].start, 'HH:mm').add(1, 'd')) : ''
        const now = moment().format('HH:mm')
        const timeToCheck = moment(firstStart, 'HH:mm').diff(moment(now, 'HH:mm'))
        setTimeout(() => {
          resetActive ? setResetActive(false) : setResetActive(true)
        }, timeToCheck)
      } else {
        const now = moment().format('HH:mm')
        const timeToCheck = moment(nextStart, 'HH:mm').diff(moment(now, 'HH:mm'))
        setTimeout(() => {
          resetActive ? setResetActive(false) : setResetActive(true)
        }, timeToCheck)
      }
    } else if (activeHAI) {
      const intervalsEnds = activeTimeInterval.map(interval => moment(interval.end, 'HH:mm').format('HH:mm'))
      const now = moment().format('HH:mm')
      const nextEnd = intervalsEnds.find(end => end > moment().format('HH:mm'))
      const timeToCheck = moment(nextEnd, 'HH:mm').diff(moment(now, 'HH:mm'))
      setTimeout(() => { resetActive ? setResetActive(false) : setResetActive(true) }, timeToCheck)
    }
  }
  const hospitalName = getHospitalNameById(hospital, hospitalId)
  const targetHospitalName = Array.isArray(targetHospitals) ? (
    targetHospitals.map((targetHospital) => (getHospitalNameById(hospital, targetHospital)))
  ) : ([targetHospitals].map((targetHospital) => (getHospitalNameById(hospital, targetHospital))))
  const bedNumbers = parseInt(numBeds, 10)
  const len = targetHospitalName.length
  const subheader = (<span title={moment(availTime).fromNow()}>{t('offerNote.subheader', { availTime })}</span>)
  const isOwner = hospital._id === hospitalId
  const targetName = targetHospitalName.map((name) => <li key={name}>{name}</li>)
  let content = ''
  let title = (t('offerNote.title', { count: bedNumbers, bedType, gender }))
  if (isOwner) {
    if (len > 1) {
      title = (
        <div data-cy="activeOfferNote">
          {t('offerNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
            ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
            ? <strong>{t('isolated')}</strong> : ''}
          <>&nbsp;</><>&#8649;</><>&nbsp;</>
          <strong>{t('various')}</strong>
        </div>
      )
      content = (
        <>
          <Trans i18nKey="offerNote.textOfferer" count={bedNumbers}>
             You offer <strong>{{ numBeds }} beds</strong> to:
          </Trans>
          <ul margin-block-start="0" type="square">
            <strong>{targetName}</strong>
          </ul>
        </>
      )
    } else {
      title = (
        <div data-cy="activeOfferNote">
          {t('offerNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
            ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
            ? <strong>{t('isolated')}</strong> : ''}
          <>&nbsp;</><>&#8649;</><>&nbsp;</>
          <strong>{targetHospitalName[0]}</strong>
        </div>
      )
    }
  } else {
    title = (
      <div data-cy="activeOfferNote">
        {t('offerNote.title', { count: bedNumbers, bedType, gender })} {covid !== 'noCovid'
          ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
          ? <strong>{t('isolated')}</strong> : ''}
        <>&nbsp;</><>&#8647;</><>&nbsp;</>
        <strong>{hospitalName}</strong>
      </div>
    )
  }

  const actions = isOwner ? (
    <>
      <Button
        disabled={!activeHAI}
        data-cy="offerNoteCancelButton"
        color="secondary"
        onClick={() => setHandleCancel(true)}
      >
        {t('cancel')}
      </Button>
      <Button
        data-cy="offerNoteCommentButton"
        color="primary"
        onClick={() => setOpenOfferComment(true)}
      >
        {t('comment')}
      </Button>
      <CommentRequestModal
        title={t('add_comment')}
        offer={item}
        open={openOfferComment}
        handleClose={() => setOpenOfferComment(false)}
        commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
      />
    </>
  ) : (
    <>
      <Button
        data-cy="offerNoteAcceptButton"
        color="primary"
        onClick={() => setOpen(true)}
      >
        {t('accept')}
      </Button>
      <AcceptOfferModal
        title={t('accept_offer')}
        offer={item}
        open={open}
        handleClose={() => setOpen(false)}
        acceptOffer={(o) => accept(o, hospital)}
      />
      <Button
        data-cy="offerNoteCommentButton"
        color="primary"
        onClick={() => setOpenOfferComment(true)}
      >
        {t('comment')}
      </Button>
      <CommentRequestModal
        title={t('add_comment')}
        offer={item}
        open={openOfferComment}
        handleClose={() => setOpenOfferComment(false)}
        commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
      />
    </>

  )

  const newRequestComments = filterComments(hospital, commentsRequested)


  const messages = newRequestComments ? [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0).concat(newRequestComments) : [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0)

  // const messages = [
  //   { name: hospitalName, text: comments, date: creationDate }
  // ].filter(row => _.size(row.text) > 0)

  return (
    <>
      <Note
        type={OFFER_TYPES.OFFER}
        raised={false}
        gender={gender}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        actions={actions}
        item={item}
        state={state}
        isOwner={isOwner}
        bedType={bedType}
        len={len}
        notifications={notifications}
        dismissNotifications={dismissNotifications}
        onClickEditButton={() => onEditOffer(item)}
        // commentR={commentR}
      />

      <Dialog
        open={handleCancel}
        onClose={() => setHandleCancel(false)}
      >
        <DialogTitle>{t('cancel_alert_title')}</DialogTitle>
        <DialogContent>
          <CancelReason type={OFFER_TYPES.OFFER} value={cancelReason} onChange={setCancelReason} />
          <DialogContentText>
            {t('cancel_alert_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleCancel(false)} color="primary">
          No
          </Button>
          <Button onClick={() => cancel(item, hospital, cancelReason)} color="primary" disabled={cancelReason.length === 0}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => ({
  hospital: state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : null
})
const mapDispatchToProps = dispatch => ({
  accept: (offer, hospital) => dispatch(acceptOffer(offer, hospital)),
  cancel: (offer, hospital, reason) => dispatch(cancelOffer(offer, hospital, reason)),
  commentR: (newComment, offerId, hospital, date) => dispatch(commentRequest(newComment, offerId, hospital, date))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(OfferNote)
)
