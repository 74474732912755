import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/ca'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import CalendarIcon from '@material-ui/icons/DateRange'
import ClockIcon from '@material-ui/icons/AccessTime'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import { OFFER_TYPES } from '../../constants/offers'

const styles = theme => {
  const unit = theme.spacing(1)
  const margin = 2 * unit

  return {
    info: {
      padding: `${margin}px ${margin}px`,
      margin: `${unit}px 0 ${margin}px`
    },
    textField: {
      marginBottom: margin
    }
  }
}

class AcceptOfferModal extends React.Component {
  state = {
    commentsOffered: '',
    patientId: '',
    arrivalTime: new Date(),
    acceptDate: new Date()
  }

  handleChange = element => event => this.setState({ [element]: event.target.value })

  isValidForm = () => {
    const { offer } = this.props
    const { patientId } = this.state

    return offer.type !== OFFER_TYPES.OFFER || _.size(patientId) > 0
  }

  render() {
    const {
      open, handleClose, classes, title, offer, acceptOffer, t
    } = this.props

    const {
      commentsOffered, patientId, arrivalTime, acceptDate
    } = this.state

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Paper className={classes.info}>
            <Typography variant="h6" gutterBottom>
              1
              &nbsp;
              {t('llit_s')}
              &nbsp;-&nbsp;
              {t(offer.bedType)}
              &nbsp;-&nbsp;
              {t(offer.gender)}
              {offer.covid !== 'noCovid' ? <span>&nbsp;-&nbsp;<strong>{t(offer.covid)}</strong></span> : ''}
              {offer.isolation === 'isolated' ? <span>&nbsp;-&nbsp;<strong>{t('isolated')}</strong></span> : ''}
            </Typography>
            {offer.type === OFFER_TYPES.REQUEST
              ? (
                <Typography variant="subtitle1">
                  {t('Id pacient')}:&nbsp;
                  {offer.patientId || '-'}
                  <div>
                    {offer.vaccinated && t('Vaccinated')}
                  </div>
                </Typography>
              )
              : ''}
            <Typography variant="subtitle1">
              {t('Comentaris')}:&nbsp;
            </Typography>
            <Typography>
              {offer.comments || '-'}
            </Typography>
          </Paper>
          {offer.type === OFFER_TYPES.OFFER && (
            <TextField
              required
              fullWidth
              label={t('Id pacient')}
              className={classes.textField}
              value={patientId}
              data-cy="idPatient"
              onChange={this.handleChange('patientId')}
            />
          )}
          <TextField
            fullWidth
            label={t('Comentaris')}
            variant="outlined"
            multiline
            rows="4"
            className={classes.textField}
            value={commentsOffered}
            data-cy="commentsOffered"
            onChange={this.handleChange('commentsOffered')}
          />
          <KeyboardDateTimePicker
            className={classes.textField}
            fullWidth
            allowKeyboardControl
            ampm={false}
            disablePast
            label={offer.type === OFFER_TYPES.OFFER ? t('Recollida') : t('available_at')}
            value={moment(arrivalTime)}
            onChange={d => this.setState({ arrivalTime: d })}
            format="DD/MM/YYYY HH:mm"
            leftArrowIcon={<LeftIcon />}
            rightArrowIcon={<RightIcon />}
            timeIcon={<ClockIcon />}
            dateRangeIcon={<CalendarIcon />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="acceptDialogAccept"
            size="small"
            color="primary"
            disabled={!this.isValidForm()}
            onClick={() => {
              acceptOffer({
                ...offer,
                commentsOffered,
                patientId: patientId || offer.patientId,
                arrivalTime: arrivalTime.toJSON(),
                acceptDate: acceptDate.toJSON(),
                newId: String(Math.random()).slice(2)
              })
              this.setState({
                commentsOffered: '', patientId: '', arrivalTime: new Date(), acceptDate: new Date()
              })
              handleClose()
            }}
          >
            {t('Acceptar')}
          </Button>
          <Button size="small" color="secondary" onClick={handleClose}>
            {t('Cancelar')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withTranslation()(AcceptOfferModal))
