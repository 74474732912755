import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const styles = (theme) => {
  const unit = theme.spacing(1)

  return {
    content: {
      '&:last-child': {
        paddingBottom: 2 * unit
      }
    }
  }
}

const NoDataNote = ({ classes, t }) => (
  <Card raised>
    <CardContent className={classes.content}>
      <Typography align="center" variant="overline">
        {t('no_available_offers')}
      </Typography>
    </CardContent>
  </Card>
)

export default withTranslation()(
  withStyles(styles)(NoDataNote)
)
