import React from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'

const CanceledOfferDialog = ({
  t, classes, hospital, item, onClose, newEvent
}) => {
  const {
    _id, type, bedType, gender, covid, isolation, patientId, numBeds, date,
    creationDate, acceptDate, comments, commentsOffered, commentsRequested,
    acceptor, involvedHospitals: [hospitalId]
  } = item
  const originName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  const acceptorName = acceptor && acceptor.name
  let title = null
  let subheader = null
  let content = null

  if (type === 'OFFER') {
    title = (
      <span>{t('acceptedOfferNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
        ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
          ? <strong>{t('isolated')}</strong> : ''}
      </span>
    )
    subheader = t('acceptedOfferNote.subheader', { date })
    content = (
      <Trans i18nKey="canceledOfferNote">
        <strong>{{ originName }}</strong> has CANCELED the offer.
      </Trans>
    )
  } else {
    title = (
      <span>{t('acceptedRequestNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
        ? <strong>{t(covid)}</strong> : ''} {isolation === 'isolated'
          ? <strong>{t('isolated')}</strong> : ''}
      </span>
    )
    subheader = t('acceptedRequestNote.subheader', { date })
    content = (
      <Trans i18nKey="canceledRequestNote">
        <strong>{{ originName }}</strong> has CANCELED the bed request for the patient with the ID: <strong>{{ patientId }}</strong>
      </Trans>
    )
  }

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)

  if (item.cancelReason) {
    content = (
      <>
      {content} <br />
      {t('reason')}: {t(item.cancelReason)}
      </>
    )
  }

  return (
    _id && (
      <OfferDialog
        onClose={onClose}
        type={OFFER_TYPES.OFFER}
        gender={gender}
        bedType={bedType}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        newEvent={newEvent}
      />
    )
  )
}

export default withTranslation()(CanceledOfferDialog)
