import { CANCEL_OFFER, CANCEL_ACCEPTED, COMPLETE_OFFER } from '../constants/offers'
import { cancelOffer, cancelAccepted, completeOffer } from './utils'

export default (state = [], action) => {
  switch (action.type) {
    case CANCEL_OFFER:
      return state.map(o => (o._id === action.offer._id ? cancelOffer(o, action.hospital) : o))
    case CANCEL_ACCEPTED:
      return state.map(o => (o._id === action.offer._id ? cancelAccepted(o, action.hospital, action.reason) : o))
    case COMPLETE_OFFER:
      return state.map(o => (o._id === action.offer._id ? completeOffer(o, action.hospital) : o))
    default:
      return state
  }
}
