import React from 'react'
import firebase from 'firebase'
import axios from 'axios'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { userLoginSuccess as userLoginSuccessAction, startRefreshTokenLifecycle as startRefreshTokenLifecycleAction } from '../actions/user'
import Login from '../components/user/Login'
import Unauthorized from '../components/user/Unauthorized'
import Loading from '../components/user/Loading'
import { config as firebaseConfig } from '../firebaseSetup'

const { loginUrl } = firebaseConfig
const FORGOT_URL = `https://auth.amalfianalytics.com/password/forgot?tenantId=${firebaseConfig.amalfiTenant}`

const cookies = new Cookies()

const mapDispatchToProps = (dispatch, props) => ({
  userLoginSuccess: (user) => {
    dispatch(userLoginSuccessAction(user, props.location))
  },
  startRefreshTokenLifecycle: () => dispatch(startRefreshTokenLifecycleAction())
})

export default (WrappedComponent) => {
  class SignInScreen extends React.Component {
    state = {
      isSignedIn: false,
      error: null,
      state: 0
    }

    componentWillMount () {
      const loginCookie = cookies.get('login')
      if (loginCookie) {
        this.setState({ state: 1 })
        return this.handleSignin(loginCookie)
      }
    }

    handleSignin = (data) => {
      const { userLoginSuccess, startRefreshTokenLifecycle } = this.props
      const { token } = data
      return Promise.all([firebase.auth().signInWithCustomToken(token), data])
        .then(signin => {
          cookies.set('login', signin[1], { path: '/' })
          this.setState({ isSignedIn: true })
          startRefreshTokenLifecycle()
          userLoginSuccess(signin[1].user)
        })
        .catch(error => {
          console.error('err', error)
          this.setState({ error, state: 0 })
        })
    }

    // Listen to the Firebase Auth state and set the local state.
    handleLogin = (user, password) => {
      const data = {
        loginId: user,
        password,
        applicationId: firebaseConfig.amalfiApplicationId
      }
      const headers = {
        Authorization: firebaseConfig.amalfiLoginKey
      }
      this.setState({ state: 1 })
      axios.post(loginUrl, data, { headers })
        .then(resp => {
          const newData = {
            token: resp.data.token,
            refreshToken: resp.data.refreshToken,
            user: {
              registrations: resp.data.user.registrations.filter(r => r.applicationId === firebaseConfig.amalfiApplicationId),
              email: resp.data.user.email
            }
          }

          return this.handleSignin(newData)
        })
        .catch(e => {
          // TODO check for 404
          this.setState({ error: { code: 'invalid credentials' }, state: 0 })
        })
    }

    render () {
      const { isSignedIn, error, state } = this.state
      const { temp } = this.props
      if (!isSignedIn) {
        if (state === 1) {
          // Show this while trying credentials
          return <Loading />
        }
        return (
          <Login
            onSubmitLogin={this.handleLogin}
            forgotUrl={FORGOT_URL}
            error={error}
          />
        )
      }
      if (temp && temp.unauthorized) {
        return <Unauthorized />
      }
      if (temp && !temp.loaded) {
        return <Loading />
      }
      return (<WrappedComponent {...this.props} />)
    }
  }
  return withRouter(connect((state) => ({
    temp: state.temp
  }), mapDispatchToProps)(SignInScreen))
}
