import {
  ACCEPT_OFFER, CREATE_OFFER, CANCEL_OFFER, CANCEL_ACCEPTED, REJECT_ACCEPTED, OFFER_TYPES, DISCHARGE_OFFER, COMPLETE_OFFER, COMMENT_REQUEST
} from '../constants/offers'
import { NEW_EVENT } from '../constants/shared'

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_OFFER:
      return [{
        date: new Date(),
        message: (action.offer.type === OFFER_TYPES.OFFER
          ? 'Oferta creada' : 'Sol·licitud creada')
      }]
    case CANCEL_OFFER:
      return [{
        date: new Date(),
        message: 'Oferta / Sol·licitud cancel·lada'
      }]
    case CANCEL_ACCEPTED:
      return [{
        date: new Date(),
        message: 'Oferta / Sol·licitud acceptada cancel·lada'
      }]
    case REJECT_ACCEPTED:
      return [{
        date: new Date(),
        message: 'Oferta / Sol·licitud acceptada rebutjada'
      }]
    case ACCEPT_OFFER:
      return [{
        date: new Date(),
        message: 'Oferta / Sol·licitud acceptada'
      }]
    case DISCHARGE_OFFER:
      return [{
        date: new Date(),
        message: "PACIENT SORTIT D'ALTA"
      }]
    case COMPLETE_OFFER:
      return [{
        date: new Date(),
        message: 'Derivació finalitzada'
      }]
    case COMMENT_REQUEST:
      return [{
        date: new Date(),
        message: 'Nou comentari'
      }]
    case NEW_EVENT:
      return [{ date: new Date(), ...action }]
    default:
      return state
  }
}
