import { NEW_EVENT, DELETE_DB } from '../constants/shared'

export const newEvent = event => ({
  type: NEW_EVENT,
  ...event
})

export const deleteDB = () => ({
  type: DELETE_DB
})

