import React from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'

const ConfirmedRequestDialog = ({
  t, classes, hospital, cancel, confirm, item, onClose, newEvent
}) => {
  const {
    _id, bedType, gender, covid, isolation, patientId, numBeds, date, creationDate, acceptDate,
    comments, commentsOffered, commentsRequested, acceptor, involvedHospitals: [hospitalId]
  } = item
  const originName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  const acceptorName = acceptor.name
  const title = (
    <span>{t('acceptedRequestNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
      ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  const subheader = t('acceptedRequestNote.subheader', { date })

  const content = (
    <>
      <Trans i18nKey="confirmedRequestNote.text">
        <strong>{{ acceptorName }}</strong> confirmed the&nbsp;
        <strong>{{ originName }}</strong>&apos; request.
      </Trans>
      {patientId && (
        <Trans i18nKey="note.patientId">
          &nbsp;<strong>Patient ID: </strong> {{ patientId }}
        </Trans>
      )}
    </>
  )

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)

  return (
    _id && (
      <OfferDialog
        onClose={onClose}
        type={OFFER_TYPES.REQUEST}
        gender={gender}
        bedType={bedType}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        newEvent={newEvent}
      />
    )
  )
}

export default withTranslation()(ConfirmedRequestDialog)
