import React from 'react'
import ReactDOM from 'react-dom'

class IdPortal extends React.Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount () {
    const { portalId } = this.props
    const root = document.getElementById(portalId)
    root.appendChild(this.el)
  }

  componentWillUnmount () {
    const { portalId } = this.props
    const root = document.getElementById(portalId)
    root.removeChild(this.el)
  }

  render () {
    const { children } = this.props
    return ReactDOM.createPortal(
      children,
      this.el
    )
  }
}

export default IdPortal
