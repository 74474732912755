import { createMuiTheme } from '@material-ui/core/styles'

// Palette generator:
export const theme = createMuiTheme({
//   palette: {
//     background: {
//       // default: '#ffffff'
//       default: '#fafbfd'
//     },
//     primary: {
//       // main:'#569c6d',
//       main: 'rgba(25, 118, 210, 1)',
//       dark: 'rgba(0, 75, 160, 1)',
//       light: 'rgba(99, 164, 255, 1)',
//       contrastText: '#ffffff'
//     },
//     secondary: {
//       main: 'rgba(255, 112, 67, 1)',
//       // dark: 'rgba(0, 75, 160, 1)',
//       // light: 'rgba(99, 164, 255, 1)'
//      },
//     // warning :{
//     //   main: 'rgba(255, 112, 67, 1)',
//     // }
//   },
//   typography: {
//     fontFamily: 'Poppins'
//     // h1: {
//     //   fontWeight: 900,
//     //   fontSize: '2rem',
//     //   textAlign: 'center',
//     //   marginBottom: '2rem',
//     //   color: '#424c5e'
//     // }
//   }
})
// console.log(THEME)
