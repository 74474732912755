// Check out this nice tool to mix colors!
// https://materialuicolors.co/
export const PALETTE = {
  CARD: {
    GENDER: {
      MALE: '#42a5f5',
      FEMALE: '#ad57c2',
      INDIFFERENT: '#767575'
      // INDIFFERENT: '#9e9d9d'
    },
    TYPE: {
      OFFER: '#C8E6C9',
      REQUEST: '#E0F7FA'
    },
    STATE: {
      REJECTED: '#fac8c8',
      OVERTIME: '#fac8c8',
      PRIORITY: '#ffefae',
      PASSIVE: '#C7C7C7',
      ACTIVE: '#E0F7FA',
      ACCEPTED: '#e6f5e6'
      // ACCEPTED: '#C8E6C9'
    }
  }
}
