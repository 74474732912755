import { connect } from 'react-redux'
import {
  acceptOffer, cancelOffer, confirmOffer,
  loadOffer, cancelAccepted, rejectAccepted,
  dischargeOffer, completeOffer, rejectRequest,
  deleteRejected, commentRequest
} from '../actions/offers'
import OfferPage from '../components/pages/OfferPage'
import { newEvent } from '../actions/shared'

const mapStateToProps = (state, { match: { params } }) => ({
  hospital: state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : {},
  // item: Object.keys(state).length > 0
  //   ? (([...state.activeOffers, ...state.acceptedOffers, ...state.confirmedOffers,
  //     ...state.rejectedRequests, ...state.extraOffer])
  //     .find(x => x && x._id === params.id) || {})
  //   : {},
  item: Object.keys(state).length > 0
    ? (([...state.activeOffers, ...state.acceptedOffers,
      ...state.canceledOffers, ...state.completedOffers])
      .find(x => x && x._id === params.id) || {})
    : {},
  loadingExtraOffer: state.temp && state.temp.loadingExtraOffer
})

const mapDispatchToProps = dispatch => ({
  accept: (offer, hospital) => dispatch(acceptOffer(offer, hospital)),
  cancel: (offer, hospital) => dispatch(cancelOffer(offer, hospital)),
  confirm: (offer, hospital) => dispatch(confirmOffer(offer, hospital)),
  discharge: (offer, hospital) => dispatch(dischargeOffer(offer, hospital)),
  complete: (offer, hospital) => dispatch(completeOffer(offer, hospital)),
  cancelA: (offer, hospital, reason) => dispatch(cancelAccepted(offer, hospital, reason)),
  rejectA: (offer, hospital, reason) => dispatch(rejectAccepted(offer, hospital, reason)),
  rejectR: (offer, hospital) => dispatch(rejectRequest(offer, hospital)),
  deleteR: (offer, hospital) => dispatch(deleteRejected(offer, hospital)),
  commentR: (newComment, offerId, hospital, date) => dispatch(commentRequest(newComment, offerId, hospital, date)),
  newEvent: message => dispatch(newEvent({ message })),
  loadOffer: offerId => dispatch(loadOffer(offerId))
})

export default connect(mapStateToProps, mapDispatchToProps)(OfferPage)
