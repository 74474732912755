import React from 'react'
import Tour from 'reactour'
import { withTranslation } from 'react-i18next'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Typography from '@material-ui/core/Typography'
import { withTheme } from '../../lib/styles'

const AppTour = ({
  theme, t, isTourOpen, setTourOpen
}) => {
  const disableBody = target => disableBodyScroll(target)
  const enableBody = target => enableBodyScroll(target)
  const accentColor = theme.palette.text.primary
  const i18nKeys = ['filterColumn', 'offersColumn', 'requestsColumn', 'acceptedColumn', 'userSettings', 'historyAccess', 'notificationsAccess']
  const steps = i18nKeys.map(key => ({
    selector: `[data-tour="${key}"]`,
    content: <Typography>{t(`tour.${key}`)}</Typography>
  }))

  return (
    <Tour
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      onRequestClose={() => setTourOpen(false)}
      steps={steps}
      isOpen={isTourOpen}
      rounded={7}
      accentColor={accentColor}
    />
  )
}

export default withTranslation()(withTheme(AppTour))
