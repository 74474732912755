import React, { useState } from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'
import AcceptOfferModal from '../offers/AcceptOfferModal'
import CommentRequestModal from '../offers/CommentRequestModal'

const ActiveOfferDialog = ({
  t, classes, hospital, cancel, accept, commentR, item, onClose, newEvent
}) => {
  const [open, setOpen] = useState(false)
  const [openRequestComment, setOpenRequestComment] = useState(false)
  const {
    _id, bedType, gender, covid, isolation, numBeds, date: availTime, creationDate, comments,
    commentsRequested, involvedHospitals: [hospitalId]
  } = item
  const hospitalName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  // const title = t('offerNote.title', { count: numBeds, bedType, gender })
  const title = (
    <span>{t('offerNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
      ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  const subheader = t('offerNote.subheader', { availTime })
  const isOwner = hospital._id === hospitalId

  const handleCancel = () => {
    cancel(item, hospital)
    onClose()
  }

  const content = (
    <Trans i18nKey="offerNote.text" count={numBeds}>
      <strong>{{ hospitalName }}</strong> offers <strong>{{ numBeds }} beds</strong>.&nbsp;
      {/* available at {{ availTime }}. */}
    </Trans>
  )

  const actions = isOwner ? (
    <>
      <Button color="secondary" onClick={handleCancel}>
        {t('cancel')}
      </Button>
      <Button
        data-cy="commentRequestedDialog"
        color="primary"
        onClick={() => setOpenRequestComment(true)}
      >
        {t('comment')}
      </Button>
      <CommentRequestModal
        title={t('add_comment')}
        offer={item}
        open={openRequestComment}
        handleClose={() => setOpenRequestComment(false)}
        // eslint-disable-next-line max-len
        commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
      />
    </>
  ) : (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
      >
        {t('accept')}
      </Button>
      <AcceptOfferModal
        title={t('accept_offer')}
        offer={item}
        open={open}
        handleClose={() => setOpen(false)}
        acceptOffer={(o) => {
          accept(o, hospital)
          onClose()
        }}
      />
      <Button
        data-cy="commentRequestedDialog"
        color="primary"
        onClick={() => setOpenRequestComment(true)}
      >
        {t('comment')}
      </Button>
      <CommentRequestModal
        title={t('add_comment')}
        offer={item}
        open={openRequestComment}
        handleClose={() => setOpenRequestComment(false)}
        // eslint-disable-next-line max-len
        commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
      />
    </>
  )

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0).concat(newRequestComments) : [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0)

  return (
    _id && (
      <OfferDialog
        onClose={onClose}
        type={OFFER_TYPES.OFFER}
        gender={gender}
        bedType={bedType}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        actions={actions}
        newEvent={newEvent}
      />
    )
  )
}

export default withTranslation()(ActiveOfferDialog)
