import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import { Provider } from 'react-redux'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
// import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { MuiThemeProvider } from '@material-ui/core/styles'
import App from './App'
import store from './store'
import * as serviceWorker from './serviceWorker'
import theme from './ui/theme'
import './rsf'
import './i18n'

MomentUtils.prototype.startOfMonth = MomentUtils.prototype.getStartOfMonth

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={window.localStorage.getItem('lng')}>
        <App />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
