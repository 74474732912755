import React from 'react'
import { withTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

const ConfirmDialog = ({
  t, open, title, message, onAccept, onClose
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button color="primary" autoFocus onClick={onAccept}>
          {t('accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(ConfirmDialog)
