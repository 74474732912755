import {
  createStore, applyMiddleware, combineReducers, compose
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import sagas from './sagas'

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  combineReducers(reducers),
  enhancers(
    applyMiddleware(sagaMiddleware)
  )
)
sagaMiddleware.run(sagas)

export default store
