import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import { dismissNotifications, undismissNotifications } from '../actions/notifications'
// import { highlightNotification } from '../actions/selectedNotification'

const mapStateToProps = state => ({
  hospital: state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : {},
  notifications: orderBy(state.notifications || [], ['date'], ['desc'])
  // selectedNotification: state.selectedNotification || ['']

})

const mapDispatchToProps = dispatch => ({
  dismissNotifications: notifications => dispatch(dismissNotifications(notifications)),
  undismissNotifications: notifications => dispatch(undismissNotifications(notifications))
  // highlightNotification: selectedNotification => dispatch(highlightNotification(selectedNotification))
})

export default connect(mapStateToProps, mapDispatchToProps)
