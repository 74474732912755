import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
// import green from '@material-ui/core/colors/green'
import CircularProgress from '@material-ui/core/CircularProgress'
import logo from '../../assets/mintLogoTemp1024x500_alpha_white.png'

const styles = theme => {
  const unit = theme.spacing(1)

  return {
    grow: {
      flexGrow: 1
    },
    appBar: {
      backgroundColor: '#456553'
    },
    logo: {
      paddingBottom: 2 * unit
    }
  }
}

function ButtonAppBar ({
  classes, hospital, isSignedIn = true, isLoading = false
}) {
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <img src={logo} height="50" className={classes.logo} alt="logo" />
        { isSignedIn ? (
          <>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              &nbsp;-&nbsp;
              {hospital.fullName}
            </Typography>
            <div id="portal-tools" />
          </>
        ) : isLoading && <CircularProgress size={25} color="inherit" />}
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(ButtonAppBar)
