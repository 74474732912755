import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import { withAppContext } from '../../lib/context'

const styles = (theme) => {
  const unit = theme.spacing(1)

  return {
    container: {
      padding: `${2 * unit}px ${unit}px`,
      height: '80vh'
    },
    tabsContainer: {
      backgroundColor: green[50],
      borderRadius: unit / 2,
      marginTop: unit
    },
    logo1: {
      height: '35px'
    },
    logo2: {
      height: '30px'
    }

  }
}

const NavigationTabs = ({
  tabIndex, setTabIndex, classes, columns, columnIcons
}) => (
  <div className={classes.container}>
    {columns[tabIndex]}
    <Tabs
      variant="fullWidth"
      centered
      indicatorColor="primary"
      textColor="primary"
      className={classes.tabsContainer}
      value={tabIndex}
      onChange={(e, val) => setTabIndex(val)}
    >

      {
        columnIcons.map((Icon, i) => (
          <Tab key={i} icon={tabIndex === i ? <img src={Icon[1]} className={classes.logo1} alt="logo" /> : <img src={Icon[0]} className={classes.logo2} alt="logo" />} disableRipple />
          // <Tab key={i} icon={<Icon />} disableRipple />
        ))
      }
    </Tabs>
  </div>
)

export default withStyles(styles)(withAppContext(NavigationTabs))
