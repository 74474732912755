import React from 'react'
import { withStyles } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
})

class SimpleSnackbar extends React.Component {
  state = {
    open: false,
    lastEvent: null
  }

  static getDerivedStateFromProps (props, state) {
    // return { open: props.event, lastEvent: props.event }
    return { open: (props.event && props.event !== state.lastEvent), lastEvent: props.event }
  }

  handleClick = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    // if (reason === 'clickaway') {
    //   return
    // }
    this.setState({ open: false })
  }

  getLastEvent = () => {
    return this.event
  }

  render () {
    const { classes, event } = this.props
    const { open } = this.state
    const message = event ? event.message : ''
    const msg = (
      <span id="message-id">
        {message}
      </span>
    )
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={msg}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    )
  }
}

export default withStyles(styles)(SimpleSnackbar)
