import React from 'react'

export const AppContext = React.createContext()

export const withAppContext = (Component) => (props) => (
  <AppContext.Consumer>
    {state => (
      <Component {...state} {...props} />
    )}
  </AppContext.Consumer>
)
