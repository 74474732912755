import {
  takeLatest, put, take, select
} from 'redux-saga/effects'
import { QUERY_SYNC, SYNC_COMPLETE } from '../constants/redux-firestore-sync'
import { SET_REGISTRATION_TOKEN, REGISTRATION_TOKEN_SYNC } from '../constants/firebase'

const fcmTokens = {
  fcmToken: [{
    collection: 'fcmTokens',
    where: ['token', '==', ':token']
  }]
}

function * syncFcmToken (action) {
  yield put({ type: QUERY_SYNC, queries: fcmTokens, params: action })
  const hospitals = yield select(state => state.hospitals)
  yield take(a => a.type === SYNC_COMPLETE && a.reducers.includes('fcmToken'))
  yield put({ type: REGISTRATION_TOKEN_SYNC, token: action.token, hospital: hospitals[0] })
}

export const watchSetRegistrationToken = takeLatest(SET_REGISTRATION_TOKEN, syncFcmToken)
