import React, { useState } from 'react'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { getHospitalNameById } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'

const RejectedOfferDialog = ({
  t, classes, hospital, item, deleteR, onClose, newEvent
}) => {
  const {
    _id, bedType, gender, covid, isolation, numBeds, date, creationDate, acceptDate, comments, commentsOffered,
    acceptor, patientId, involvedHospitals: [hospitalId]
  } = item
  const [handleDelete, setHandleDelete] = useState(false)
  const originName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  const acceptorName = acceptor && acceptor.name
  // const title = t('acceptedOfferNote.title', { count: numBeds, bedType, gender })
  const title = (
    <span>{t('acceptedOfferNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
      ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  const subheader = t('acceptedOfferNote.subheader', { date })
  const content = t('rejectedOfferNote', { patientId })

  const messages = [
    { name: originName, text: comments, date: creationDate },
    { name: acceptorName, text: commentsOffered, date: acceptDate }
  ].filter(row => _.size(row.text) > 0)

  const deleteAndClose = () => {
    deleteR(item, hospital)
    onClose()
  }

  const actions = (
    <Button
      data-cy="requestNoteCancelButton"
      color="secondary"
      onClick={() => setHandleDelete(true)}
    >
      {t('delete')}
    </Button>
  )

  return (
    _id && (
      <>
        <OfferDialog
          onClose={onClose}
          type={OFFER_TYPES.OFFER}
          gender={gender}
          bedType={bedType}
          covid={covid}
          isolation={isolation}
          title={title}
          actions={actions}
          subheader={subheader}
          messages={messages}
          content={content}
          newEvent={newEvent}
        />
        <Dialog
          open={handleDelete}
          onClose={() => setHandleDelete(false)}
        >
          <DialogTitle>{t('delete_alert_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('cancel_request_alert_content')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHandleDelete(false)} color="primary">
          No
            </Button>
            <Button data-cy="rejectRequestConfirmation" onClick={() => deleteAndClose(item, hospital)} color="primary">
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

export default withTranslation()(RejectedOfferDialog)
