
export const BED_TYPES = [
  'acute',
  // 'intensive',
  // 'medium',
  'subacute',
  // 'postacute',
  'convalescent',
  'longstay',
  'paliative'
  // 'mental_health',
  // 'covid19'
]
export const GENDERS = [
  'male_',
  'female',
  'indifferent'
]
export const ISOLATION = [
  'noIsolated',
  'isolated'
]
export const COVID = [
  'covid',
  'noCovid',
  'postCovid'
]
export const NEW_EVENT = 'NEW_EVENT'
export const DELETE_DB = 'DELETE_DB'
