import { SET_FILTER } from '../constants/offers'
import { UNAUTHORIZED_USER } from '../constants/user'
import { SWITCH_HOSPITAL } from '../constants/firebase'
import { SYNC_START, SYNC_COMPLETE, QUERY_SYNC } from '../constants/redux-firestore-sync'

const defaultTemp = {
  offersFilter: {
    bedType: '',
    gender: ''
  },
  unauthorized: false,
  loading: [],
  loaded: false,
  loadingExtraOffer: null
}

const NO_LOADING = ['extraOffer']

const addLoading = (state, action) => {
  const { reducerName } = action
  if (NO_LOADING.indexOf(reducerName) > -1) {
    return state
  }
  return { ...state, loading: [...state.loading, reducerName], loaded: false }
}

const removeLoading = (state, action) => {
  const { reducers } = action
  const { loading } = state
  const newLoading = loading.filter(l => !reducers.includes(l))
  return { ...state, loading: newLoading, loaded: newLoading.length === 0 }
}

export default (state = defaultTemp, action) => {
  switch (action.type) {
    case SET_FILTER:
      return { ...state, offersFilter: action.filter }
    case UNAUTHORIZED_USER:
      return { ...state, unauthorized: true }
    case SWITCH_HOSPITAL:
      return { ...state, unauthorized: false }
    case SYNC_START:
      return addLoading(state, action)
    case SYNC_COMPLETE:
      return removeLoading(state, action)
    case QUERY_SYNC:
      return action.queries.extraOffer ? { ...state, loadingExtraOffer: action.params.offerId } : state
    default:
      return state
  }
}
