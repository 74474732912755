import React from 'react'
import { useTranslation } from 'react-i18next'
import authenticated from '../../HOCs/authenticated'
import AlertDialog from '../dialogs/AlertDialog'
import MainView from '../../containers/MainView'

const NotFoundPage = ({ history }) => {
  const { t } = useTranslation()
  const goHome = () => {
    history.push('/')
  }

  return (
    <>
      <MainView />
      <AlertDialog open title={t('pageNotFound.title')} message={t('pageNotFound.message')} onAccept={goHome} onClose={goHome} />
    </>
  )
}

export default authenticated(NotFoundPage)
