import React, { useState } from 'react'
import _ from 'lodash'
import { withTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import ShowMoreIcon from '@material-ui/icons/KeyboardArrowRight'
import ShowLessIcon from '@material-ui/icons/KeyboardArrowDown'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'
import AcceptOfferModal from '../offers/AcceptOfferModal'
import CommentRequestModal from '../offers/CommentRequestModal'

const ActiveRequestDialog = ({
  t, classes, hospital, cancel, accept, commentR, rejectR, item, onClose, newEvent
}) => {
  const [handleReject, setHandleReject] = useState(false)
  const [open, setOpen] = useState(false)
  const [openRequestComment, setOpenRequestComment] = useState(false)
  const [expandDestination, setExpandDestination] = useState(false)
  const {
    _id, state, bedType, gender, covid, isolation, numBeds, patientId, date: availTime, creationDate,
    comments, commentsRequested, targetHospitalsNames, involvedHospitals: [hospitalId], vaccinated
  } = item

  const hospitalName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  // const title = t('offerNote.title', { count: numBeds, bedType, gender })
  const title = (
    <span>{t('offerNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
      ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  // const subheader = t('offerNote.subheader', { availTime })
  const subheader = t('requestNote.subheader', { availTime })
  const isOwner = hospital._id === hospitalId
  const targetName = targetHospitalsNames ? (targetHospitalsNames.map((name) => <li key={name}>{name}</li>)) : ''
  const len = targetHospitalsNames ? targetHospitalsNames.length : 0

  const handleCancel = () => {
    cancel(item, hospital)
    onClose()
  }

  const rejectAndClose = () => {
    rejectR(item, hospital)
    onClose()
  }

  const content = (
    <>
      <Trans i18nKey="requestNote.text">
        <strong>{{ hospitalName }}</strong> requests a beds.
      </Trans>
      <div>
        {patientId && (
          <Trans i18nKey="note.patientId">
         Patient ID: <strong>{{ patientId }}</strong>
          </Trans>
        )}
        {vaccinated && t('Vaccinated')}
      </div>
      {(len > 1) && (state === 'ACTIVE' || state === 'REJECTED') ? (
        <>
          <Trans i18nKey="requestNote.textRequestSendTo">
        Request send to:
          </Trans>
          <Button onClick={() => { setExpandDestination(!expandDestination) }}>
            {expandDestination ? <ShowMoreIcon /> : <ShowLessIcon />}
          </Button>
          {expandDestination ? ''
            : (
              <ul margin-block-start="0" type="square">
                <strong>{targetName}</strong>
              </ul>
            )}
        </>
      ) : ''}
    </>
  )

  const actions = isOwner ? (
    <>
      <Button color="secondary" onClick={handleCancel}>
        {t('cancel')}
      </Button>
      <Button
        data-cy="commentRequestedDialog"
        color="primary"
        onClick={() => setOpenRequestComment(true)}
      >
        {t('comment')}
      </Button>
      <CommentRequestModal
        title={t('add_comment')}
        offer={item}
        open={openRequestComment}
        handleClose={() => setOpenRequestComment(false)}
        // eslint-disable-next-line max-len
        commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
      />
    </>
  ) : (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
      >
        {t('accept')}
      </Button>
      <AcceptOfferModal
        title={t('accept_offer')}
        offer={item}
        open={open}
        handleClose={() => setOpen(false)}
        acceptOffer={(o) => {
          accept(o, hospital)
          onClose()
        }}
      />
      {/* <Button
        data-cy="requestNoteRejectButton"
        color="secondary"
        onClick={() => setHandleReject(true)}
      >
        {t('Reject')}
      </Button> */}
      <Button
        data-cy="commentRequestedDialog"
        color="primary"
        onClick={() => setOpenRequestComment(true)}
      >
        {t('comment')}
      </Button>
      <CommentRequestModal
        title={t('add_comment')}
        offer={item}
        open={openRequestComment}
        handleClose={() => setOpenRequestComment(false)}
        commentRequest={(newComment) => commentR(newComment, item._id, hospital, new Date().toJSON())}
      />

      <Dialog
        open={handleReject}
        onClose={() => setHandleReject(false)}
      >
        <DialogTitle>{t('reject_alert_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('cancel_request_alert_content')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHandleReject(false)} color="primary">
          No
          </Button>
          <Button data-cy="rejectRequestConfirmation" onClick={() => rejectAndClose(item, hospital)} color="primary">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0).concat(newRequestComments) : [
    { name: hospitalName, text: comments, date: creationDate }
  ].filter(row => _.size(row.text) > 0)

  return (
    _id && (
      <OfferDialog
        onClose={onClose}
        type={OFFER_TYPES.REQUEST}
        gender={gender}
        bedType={bedType}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        actions={actions}
        newEvent={newEvent}
      />
    )
  )
}

export default withTranslation()(ActiveRequestDialog)
