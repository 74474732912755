import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import authenticated from '../../HOCs/authenticated'
import MainView from '../../containers/MainView'
import ActiveRequestDialog from '../dialogs/ActiveRequestDialog'
import ActiveOfferDialog from '../dialogs/ActiveOfferDialog'
import AcceptedRequestDialog from '../dialogs/AcceptedRequestDialog'
import AcceptedOfferDialog from '../dialogs/AcceptedOfferDialog'
import ConfirmedRequestDialog from '../dialogs/ConfirmedRequestDialog'
import ConfirmedOfferDialog from '../dialogs/ConfirmedOfferDialog'
import CanceledOfferDialog from '../dialogs/CanceledOfferDialog'
import CompletedOfferDialog from '../dialogs/CompletedOfferDialog'
import DischargedOfferDialog from '../dialogs/DischargedOfferDialog'
import DischargedRequestDialog from '../dialogs/DischargedRequestDialog'
import RejectedOfferDialog from '../dialogs/RejectedOfferDialog'
import AcceptedRejectedDialog from '../dialogs/AcceptedRejectedDialog'
import AlertDialog from '../dialogs/AlertDialog'

import {
  OFFER_TYPES,
  STATE_ACTIVE,
  STATE_ACCEPTED,
  STATE_CONFIRMED,
  STATE_CANCELED,
  STATE_ACCEPTED_REJECTED,
  STATE_REJECTED,
  STATE_DISCHARGED,
  STATE_COMPLETED
} from '../../constants/offers'

const OfferPage = ({
  history, hospital, item, cancel, cancelA, rejectA, rejectR, accept, commentR, confirm,
  discharge, complete, deleteR, t, newEvent, match, loadOffer, loadingExtraOffer
}) => {
  useEffect(() => {
    if (loadingExtraOffer !== match.params.id && Object.keys(item).length === 0) {
      loadOffer(match.params.id)
    }
  }, [match.params.id])
  const handleClose = () => history.push('/')
  let dialog = null
  if (item._id) {
    if (item.state === STATE_ACTIVE) {
      dialog = item.type === OFFER_TYPES.REQUEST
        ? (
          <ActiveRequestDialog
            onClose={handleClose}
            item={item}
            cancel={cancel}
            rejectR={rejectR}
            accept={accept}
            commentR={commentR}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
        : (
          <ActiveOfferDialog
            onClose={handleClose}
            item={item}
            cancel={cancel}
            accept={accept}
            commentR={commentR}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
    } else if (item.state === STATE_ACCEPTED) {
      dialog = item.type === OFFER_TYPES.REQUEST
        ? (
          <AcceptedRequestDialog
            onClose={handleClose}
            item={item}
            rejectA={rejectA}
            cancelA={cancelA}
            confirm={confirm}
            discharge={discharge}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
        : (
          <AcceptedOfferDialog
            onClose={handleClose}
            item={item}
            rejectA={rejectA}
            cancelA={cancelA}
            discharge={discharge}
            confirm={confirm}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
    } else if (item.state === STATE_DISCHARGED) {
      dialog = item.type === OFFER_TYPES.REQUEST
        ? (
          <DischargedRequestDialog
            onClose={handleClose}
            item={item}
            complete={complete}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
        : (
          <DischargedOfferDialog
            onClose={handleClose}
            item={item}
            complete={complete}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
    } else if (item.state === STATE_CONFIRMED) {
      dialog = item.type === OFFER_TYPES.REQUEST
        ? (
          <ConfirmedRequestDialog
            onClose={handleClose}
            item={item}
            cancel={cancel}
            confirm={confirm}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
        : (
          <ConfirmedOfferDialog
            onClose={handleClose}
            item={item}
            cancel={cancel}
            confirm={confirm}
            hospital={hospital}
            newEvent={newEvent}
          />
        )
    } else if (item.state === STATE_CANCELED) {
      dialog = (
        <CanceledOfferDialog
          onClose={handleClose}
          item={item}
          hospital={hospital}
          newEvent={newEvent}
        />
      )
    } else if (item.state === STATE_ACCEPTED_REJECTED) {
      dialog = (
        <AcceptedRejectedDialog
          onClose={handleClose}
          item={item}
          hospital={hospital}
          newEvent={newEvent}
        />
      )
    } else if ((item.state === STATE_REJECTED)) {
      dialog = (
        <RejectedOfferDialog
          onClose={handleClose}
          item={item}
          deleteR={deleteR}
          hospital={hospital}
          newEvent={newEvent}
        />
      )
    } else if (item.state === STATE_COMPLETED) {
      dialog = (
        <CompletedOfferDialog
          onClose={handleClose}
          item={item}
          hospital={hospital}
          newEvent={newEvent}
        />
      )
    }
  } else {
    dialog = (
      <AlertDialog
        open
        title={t('offerNotFound.title')}
        message={t('offerNotFound.message')}
        onAccept={handleClose}
        onClose={handleClose}
      />
    )
  }

  return (
    <>
      <MainView />
      {dialog}
    </>
  )
}

export default authenticated(withTranslation()(OfferPage))
