import {
  CREATE_OFFER, STATE_ACTIVE, ACCEPT_OFFER, CANCEL_OFFER, OFFER_TYPES, REJECT_REQUEST, DELETE_OFFER, COMMENT_REQUEST, EDIT_OFFER
} from '../constants/offers'
import {
  acceptOffer, updateLog, cancelOffer, rejectRequest, deleteRejected, commentRequest
} from './utils'

const newOffer = (action, edit) => (action.offer.type === OFFER_TYPES.OFFER
  ? ({
    ...action.offer,
    sourceHospitals: action.hospital._id,
    targetHospitals: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? action.hospital.source.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h._id)
      : action.offer.targetHosp,
    // targetHospitals: action.offer.targetHosp === undefined || action.offer.targetHosp === 'all'
    //   ? action.hospital.source.map(h => h._id) : action.offer.targetHosp,
    targetHospitalsNames: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? action.hospital.source.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h.name)
      : action.hospital.source.filter(bt => (bt._id.includes(action.offer.targetHosp))).map(h => h.name),
    // targetHospitalsNames: action.offer.targetHosp === undefined || action.offer.targetHosp === 'all'
    //   ? action.hospital.source.map(h => h.name)
    //   : action.hospital.source.filter(bt => (bt._id.includes(action.offer.targetHosp))).map(h => h.name),
    // // : action.offer.targetHosp,
    targetHospitalsAvailable: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? action.hospital.source.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h._id)
      : action.offer.targetHosp,
    // targetHospitalsAvailable: action.offer.targetHosp === undefined || action.offer.targetHosp === 'all'
    //   ? action.hospital.source.map(h => h._id) : action.offer.targetHosp,
    involvedHospitals: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? [action.hospital._id, ...action.hospital.source.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h._id)]
      : [action.hospital._id, ...action.offer.targetHosp],
    // involvedHospitals: action.offer.targetHosp === undefined || action.offer.targetHosp === 'all'
    //   ? [action.hospital._id, ...action.hospital.source.map(h => h._id)] : [action.hospital._id, action.offer.targetHosp],

    state: STATE_ACTIVE,
    log: edit
      ? updateLog(action.offer, { hospitalId: action.hospital._id }, 'Oferta Editada', 'Oferta Editada')
      : updateLog(action.offer, { hospitalId: action.hospital._id }, 'Nova oferta', 'Nova oferta')

  })

  : ({
    ...action.offer,
    sourceHospitals: action.hospital._id,
    targetHospitals: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? action.hospital.target.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h._id)
      : action.offer.targetHosp,
    targetHospitalsNames: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? action.hospital.target.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h.name)
      : action.hospital.target.filter(bt => (bt._id.includes(action.offer.targetHosp))).map(h => h.name),
    // : action.offer.targetHosp,
    targetHospitalsAvailable: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? action.hospital.target.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h._id)
      : action.offer.targetHosp,
    involvedHospitals: action.offer.targetHosp === undefined || action.offer.targetHosp.length === 0
      ? [action.hospital._id, ...action.hospital.target.filter(bt => (bt.bedTypes ? bt.bedTypes.includes(action.offer.bedType) : null)).map(h => h._id)]
      : [action.hospital._id, ...action.offer.targetHosp],
    state: STATE_ACTIVE,
    log: edit
      ? updateLog(action.offer, { hospitalId: action.hospital._id }, 'Sol·licitud Editada', 'Sol·licitud Editada')
      : updateLog(action.offer, { hospitalId: action.hospital._id }, 'Nova sol·licitud', 'Nova sol·licitud')
  }))

export default (state = [], action) => {
  switch (action.type) {
    case CREATE_OFFER:
      return [...state, newOffer(action)]
    case EDIT_OFFER:
      // newOffer is used for consistency here
      return state.map(o => (o._id === action.offer._id ? newOffer(action, true) : o))
    case ACCEPT_OFFER:
      return acceptOffer(state, action)
    case CANCEL_OFFER:
      return state.map(o => (o._id === action.offer._id ? cancelOffer(o, action.hospital, action.reason) : o))
    case REJECT_REQUEST:
      return rejectRequest(state, action)
    case DELETE_OFFER:
      return state.map(o => (o._id === action.offer._id ? deleteRejected(o, action.hospital) : o))
    case COMMENT_REQUEST:
      return state.map(o => (o._id === action.offerId ? commentRequest(o, action) : o))
    default:
      return state
  }
}
