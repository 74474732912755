import activeOffers from './activeOffers'
import acceptedOffers from './acceptedOffers'
import confirmedOffers from './confirmedOffers'
import canceledOffers from './canceledOffers'
import completedOffers from './completedOffers'
import rejectedDeletedOffers from './rejectedDeletedOffers'
import hospitals from './hospitals'
import fcmToken from './fcmToken'
import events from './events'
import notifications from './notifications'
import temp from './temp'
import extraOffer from './extraOffer'

export default {
  hospitals,
  fcmToken,
  events,
  temp,
  activeOffers,
  acceptedOffers,
  confirmedOffers,
  canceledOffers,
  completedOffers,
  rejectedDeletedOffers,
  notifications,
  extraOffer
}
