import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import MainView from '../components/navigation/MainView'
import {
  createOffer, editOffer, acceptOffer, commentRequest, cancelOffer, confirmOffer, deleteRejected
} from '../actions/offers'
import { dismissNotifications } from '../actions/notifications'

const isNotRejected = (hospital) => (offer) => {
  return hospital && (!offer.rejector || offer.rejector.indexOf(hospital._id) === -1)
}

const mapStateToProps = state => {
  // const selectedNotification = state.selectedNotification
  const hospital = state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : {}
  const activeOffers = (state.activeOffers || []).map((item) => {
    const { involvedHospitals: [hospitalId] } = item

    // priority: !!item.priority is to make existing offers work. It can be removed after a month.
    return { ...item, isOwner: hospitalId === hospital._id, priority: !!item.priority }
  })
  const acceptedOffers = (state.acceptedOffers || []).map((item) => {
    const { involvedHospitals: [hospitalId] } = item

    return { ...item, isOwner: hospitalId === hospital._id }
  })
  const dischargedOffers = (state.dischargedOffer || []).map((item) => {
    const { involvedHospitals: [hospitalId] } = item

    return { ...item, isOwner: hospitalId === hospital._id }
  })
  const canceledOffers = (state.canceledOffers || []).map((item) => {
    const { involvedHospitals: [hospitalId] } = item

    return { ...item, isOwner: hospitalId === hospital._id }
  })
  const completedOffers = (state.completedOffers || []).map((item) => {
    const { involvedHospitals: [hospitalId] } = item

    return { ...item, isOwner: hospitalId === hospital._id }
  })
  const rejectedDeletedOffers = (state.rejectedDeletedOffers || []).map((item) => {
    const { involvedHospitals: [hospitalId] } = item

    return { ...item, isOwner: hospitalId === hospital._id }
  })

  return {
    // the user's offers (isOwner) appears in LAST place
    activeOffers: orderBy(activeOffers, ['type', 'priority', 'date'], ['desc', 'desc', 'asc']).filter(isNotRejected(hospital)),
    // the user's offers (isOwner) appears in FIRST place

    acceptedOffers: orderBy(acceptedOffers, ['isOwner', 'arrivalTime'], ['desc', 'desc']).filter(isNotRejected(hospital)),
    confirmedOffers: orderBy(state.confirmedOffers, ['arrivalTime'], ['desc']).filter(isNotRejected(hospital)),
    dischargedOffers: orderBy(dischargedOffers, ['arrivalTime'], ['desc']).filter(isNotRejected(hospital)),
    canceledOffers: orderBy(canceledOffers, ['isOwner', 'arrivalTime'], ['desc', 'desc']).filter(isNotRejected(hospital)),
    completedOffers: orderBy(completedOffers, ['isOwner', 'arrivalTime'], ['desc', 'desc']).filter(isNotRejected(hospital)),
    rejectedDeletedOffers: orderBy(rejectedDeletedOffers, ['isOwner', 'arrivalTime'], ['desc', 'desc']),
    notifications: state.notifications || [],
    hospital: state.hospitals && state.hospitals.length > 0 ? state.hospitals[0] : null
    // selectedNotification
  }
}
const mapDispatchToProps = dispatch => ({
  createOffer: (offer, hospital) => dispatch(createOffer(offer, hospital)),
  editOffer: (offer, hospital) => dispatch(editOffer(offer, hospital)),
  acceptOffer: (offer, hospital) => dispatch(acceptOffer(offer, hospital)),
  cancelOffer: (offer, hospital) => dispatch(cancelOffer(offer, hospital)),
  confirmOffer: (offer, hospital) => dispatch(confirmOffer(offer, hospital)),
  deleteRejected: (offer, hospital) => dispatch(deleteRejected(offer, hospital)),
  commentRequest: (offer, hospital) => dispatch(commentRequest(offer, hospital)),
  dismissNotifications: notifications => dispatch(dismissNotifications(notifications))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainView)
