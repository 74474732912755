import React, { useState } from 'react'
// import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import amalfiLogo from '../../assets/AMALFI_LOGO.png'
import logo from '../../assets/mintLogoTemp_alpha.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  error: {
    color: 'red'
  },
  paper: {
    padding: theme.spacing(6),
    margin: theme.spacing(9),
    maxWidth: 500,
    boxShadow: '5px 5px 10px #888888'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1)
  },
  logoMint: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3)
  },
  button: {
    backgroundColor: '#009A44',
    color: '#ffffff',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  a: {
    textDecoration: 'none',
    color: '#888888',
    display: 'flex',
    justifyContent: 'right',
    fontFamily: theme.typography.fontFamily
  },
  form: {
    marginBottom: theme.spacing(2)
  }
}))

const LOGIN_ERRORS = {
  'auth/invalid-custom-token': 'Session expired.',
  'invalid credentials': 'Invalid user/password.'
}

export default ({
  onSubmitLogin, forgotUrl, error
}) => {
  const classes = useStyles()
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classes.root}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault()
          onSubmitLogin(user, password)
        }
      }}

    >
      <Paper className={classes.paper}>
        <div className={classes.logo}><img src={amalfiLogo} height="100" alt="amalfiLogo" /></div>
        <div className={classes.logoMint}><img src={logo} height="60" alt="mintLogo" /></div>
        <form>
          <div>
            <TextField
              fullWidth
              className={classes.form}
              variant="outlined"
              name="user"
              label="User"
              value={user}
              onChange={e => setUser((e.target.value).trim())}
            />
          </div>
          <div>
            <TextField
              fullWidth
              className={classes.form}
              variant="outlined"
              name="password"
              type="password"
              label="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <Button
            fullWidth
            className={classes.button}
            name="submit"
            variant="contained"
            onClick={() => onSubmitLogin(user, password)}
          > Login
          </Button>
          <a className={classes.a} href={forgotUrl}>Forgot password</a>
          <span className={classes.error}>{error && error.code && LOGIN_ERRORS[error.code]}</span>
        </form>
      </Paper>
    </div>
  )
}
