import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { withTranslation, Trans } from 'react-i18next'
import { getHospitalNameById, filterComments } from '../../lib/utils'
import { OFFER_TYPES } from '../../constants/offers'
import OfferDialog from './OfferDialog'

const CompletedOfferDialog = ({
  t, classes, hospital, item, onClose, newEvent
}) => {
  const {
    _id, type, bedType, gender, covid, isolation, patientId, numBeds, date,
    creationDate, acceptDate, comments, commentsOffered, completedDate,
    commentsRequested, acceptor, involvedHospitals: [hospitalId]
  } = item
  const originName = _id ? getHospitalNameById(hospital, hospitalId) : ''
  const acceptorName = acceptor && acceptor.name
  const completedT = moment(completedDate).format('HH:mm (DD/MM/YYYY)')
  // const title = t('acceptedOfferNote.title', { count: numBeds, bedType, gender })
  const title = (
    <span>{t('acceptedOfferNote.title', { count: numBeds, bedType, gender })} {covid !== 'noCovid'
      ? <strong>{ t(covid) }</strong> : ''} {isolation === 'isolated'
      ? <strong>{t('isolated')}</strong> : ''}
    </span>
  )
  const subheader = t('acceptedOfferNote.subheader', { date })
  const isOwner = hospital._id === hospitalId
  let content = null

  if (type === 'OFFER' && isOwner) {
    content = (
      <div data-cy="dischargedRequestNote">
        <Trans i18nKey="note.patientId">
      Patient ID: <strong> {{ patientId }} </strong>
        </Trans>
        <div> <strong>{t('referralCompleted')}</strong>&nbsp;{t('at')}&nbsp;{completedT}&nbsp;{t('from')} <strong>{acceptorName}</strong></div>
      </div>
    )
  } else if (type === 'OFFER' && !isOwner) {
    content = (
      <div data-cy="dischargedRequestNote">
        <Trans i18nKey="note.patientId">
    Patient ID: <strong> {{ patientId }} </strong>
        </Trans>
        <div> <strong>{t('referralCompleted')}</strong>&nbsp;{t('at')}&nbsp;{completedT}&nbsp;{t('to')} <strong>{originName}</strong></div>
      </div>
    )
  } else if (type === 'REQUEST' && isOwner) {
    content = (
      <div data-cy="dischargedRequestNote">
        <Trans i18nKey="note.patientId">
      Patient ID: <strong> {{ patientId }} </strong>
        </Trans>
        <div> <strong>{t('referralCompleted')}</strong>&nbsp;{t('at')}&nbsp;{completedT}&nbsp;{t('to')} <strong>{acceptorName}</strong></div>
      </div>
    )
  } else {
    content = (
      <div data-cy="dischargedRequestNote">
        <Trans i18nKey="note.patientId">
    Patient ID: <strong> {{ patientId }} </strong>
        </Trans>
        <div> <strong>{t('referralCompleted')}</strong>&nbsp;{t('at')}&nbsp;{completedT}&nbsp;{t('from')} <strong>{originName}</strong></div>
      </div>
    )
  }

const newRequestComments = filterComments(hospital, commentsRequested)

  const messages = newRequestComments ? [
    // eslint-disable-next-line max-len
    { name: originName, text: comments, date: creationDate }].filter(row => _.size(row.text) > 0).concat(newRequestComments).concat([{ name: acceptorName, text: commentsOffered, date: acceptDate }].filter(row => _.size(row.text) > 0))
    : [
      { name: originName, text: comments, date: creationDate },
      { name: acceptorName, text: commentsOffered, date: acceptDate }
    ].filter(row => _.size(row.text) > 0)
  return (
    _id && (
      <OfferDialog
        onClose={onClose}
        type={OFFER_TYPES.OFFER}
        gender={gender}
        bedType={bedType}
        covid={covid}
        isolation={isolation}
        title={title}
        subheader={subheader}
        messages={messages}
        content={content}
        newEvent={newEvent}
      />
    )
  )
}

export default withTranslation()(CompletedOfferDialog)
